import React from "react";
import './navbar-layout.css'
import { Outlet, Link } from "react-router-dom";
import { useState } from "react";
import $ from 'jquery'
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { faAngleDoubleUp, faMagnifyingGlass, faPersonWalkingLuggage, faPhone, faRightToBracket } from "@fortawesome/free-solid-svg-icons"
import nystailogo from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/logoooo 1.png'
import seclogo from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/full-logo-nys.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faBlog } from '@fortawesome/free-solid-svg-icons';
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'



const Layout = () => {

  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  $(document).ready(function () {
    $('.navbar-light .dmenu').hover(function () {
      $(this).find('.sm-menu').first().stop(true, true).slideDown(150);
    }, function () {
      $(this).find('.sm-menu').first().stop(true, true).slideUp(105)
    });
  });

  $(document).ready(function () {
    $(".megamenu").on("click", function (e) {
      e.stopPropagation();
    });
  });


  const [hovered, setHovered] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const openNav = () => {
    setNavOpen(true);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  function scrollTop() {

    if ($(window).scrollTop() > 200) {
      $(".backToTopBtn").addClass("active");
    } else {
      $(".backToTopBtn").removeClass("active");
    }
  }
  $(function () {
    scrollTop();
    $(window).on("scroll", scrollTop);


    $(".backToTopBtn").click(function () {
      $("html, body").animate({ scrollTop: 0 }, 1);
      return false;
    });
  });

  window.onscroll = function () { scrollFunction() };

  function scrollFunction() {
    const navbar = document.querySelector("#navbar-solution-nys-secnav");
    if (navbar) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        navbar.style.top = "0";
      } else {
        navbar.style.top = "-450px";
      }
    }
  }

  const [searchVisible, setSearchVisible] = useState(false);
  const [searchVisible1, setSearchVisible1] = useState(false);

  const toggleSearchBox = () => {
    setSearchVisible(prevState => !prevState);
    setSearchVisible(!searchVisible);
  };
  const toggleSearchBox1 = () => {
    setSearchVisible1(prevState1 => (!prevState1));
  };

  return (
    <>

      {/* desktop nav start */}
      <div className=" col-12  mainnav-nystai " >
        <div className="logo-div-nystai col-lg-2 col-md-2  ">
          <Link to="nystai-home"> <img src={nystailogo} className=" logo-nystai" alt="nyslogo" /></Link>
        </div>
        <div className=" col-lg-8 col-md-8 navdiv-nystai-main">
          <div className=" navdiv-nystai">
            <div className=" icon-number-div-nystai-main">
              <div className=" icon-number-div-nystai">
                <div className="social-icon-nav-nystai">
                  <p><FontAwesomeIcon icon={faInstagram} style={{ color: '#E1306C' }} className="icon-scocial-text-nystai" /></p>
                  <p><FontAwesomeIcon icon={faWhatsapp} style={{ color: '#25D366' }} className="icon-scocial-text-nystai" /></p>
                  <p><FontAwesomeIcon icon={faYoutube} style={{ color: '#FF0000' }} className="icon-scocial-text-nystai" /></p>
                  <p><FontAwesomeIcon icon={faTwitter} style={{ color: '#1DA1F2' }} className="icon-scocial-text-nystai" /></p>
                  <p><FontAwesomeIcon icon={faFacebook} style={{ color: '#1877F2' }} className="icon-scocial-text-nystai" /></p>
                  <p><FontAwesomeIcon icon={faTelegramPlane} style={{ color: '#0088CC' }} className="icon-scocial-text-nystai icon-telegram" /></p>
                  <p><FontAwesomeIcon icon={faXTwitter} style={{ color: '#000' }} className="icon-scocial-text-nystai" /></p>
                  <p><FontAwesomeIcon icon={faBlog} style={{ color: '#FFA500' }} className="icon-scocial-text-nystai" /></p>
                  <p><FontAwesomeIcon icon={faEnvelope} style={{ color: '#3e65cf' }} className="icon-scocial-text-nystai" /></p>
                </div>
              </div>
            </div>
            <div className=" nav-topics-nystai-home" >
              <h6 class="text-nav-topics-nystai"> <Link to="nystai-product" className={`text-nav-topics-nystai ${isActive('/nystai-product')}`}>PRODUCTS </Link></h6>
              <h6 class="text-nav-topics-nystai"><Link to="nystai-solution" className={`text-nav-topics-nystai ${isActive('/nystai-solution')}`}>INTEGRATED SOLUTIONS</Link></h6>
              <h6 className=" text-nav-topics-nystai">PROTECT PLAN</h6>
              <h6 className=" text-nav-topics-nystai">SERVICE </h6>
              <h6 className=" text-nav-topics-nystai" ><Link to="nystai-support" className={`text-nav-topics-nystai ${isActive('/nystai-support')}`}
              >SUPPORT</Link></h6>
            </div>
          </div >
        </div >
        <div className="col-lg-2 col-md-2 col-sm-2 right-ssl-icon-nystai ">
          <div className="right-ssl-icon-nystai-number d-flex align-items-center justify-content-center">

            <p href="https://wa.me/+918189977700" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                icon={faPhone}
                shake
                style={{ color: '#000' }}
                className="me-2"
              />
            </p>
            <p className="text-number-nystai" href="tel:+91 81899 77700">+91 81899 77700</p>
          </div>


          <div className="right-ssl-icon-nystai-login" >


            <div className="div-2-icon ">
              <div>
                <p >
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="fa-search ricon-text-nystai" style={{ cursor: "pointer" }} onClick={toggleSearchBox} />
                </p>
              </div>

            </div>

            <p data-tooltip-id="my-tooltip" data-tooltip-content="Sign in"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              data-tip="Sign In"
            >
              <FontAwesomeIcon
                icon={hovered ? faPersonWalkingLuggage : faRightToBracket}
                className="ricon-text-nystai"
              />
            </p>
            <Tooltip id="my-tooltip" place="bottom" className="tooltip" />

            <p ><FontAwesomeIcon icon={faShoppingBasket} bounce className="ricon-text-nystai " /></p>
          </div>
        </div>

        <p href="#"><button class="backToTopBtn"><FontAwesomeIcon icon={faAngleDoubleUp} className="icon-top" /></button></p>

      </div>
      {/* desktop nav end */}

      {/* <serch star */}
      <div className="search-visible">
        {searchVisible && (
          <>
            <div className="search-box col-12">
              <div className='d-flex'>
                <input
                  onClick={toggleSearchBox1}
                  type="text"
                  placeholder="Search our products..."
                  className="form-field me-4 "
                  autoFocus

                />

                <input
                  type="button"
                  className="button-go "
                  value="Go"
                />

                {searchVisible && (
                  <button className="close-icon-nys">
                    &times;
                  </button>
                )}


              </div>
              {searchVisible1 && (
                <>
                  <div className="Sear-visi-1-nys">
                    <h5>Products</h5>
                    <h6>Turbo HD 8.0</h6>
                    <h6>search-box</h6>
                    <h6>Solar-powered Security Cameras</h6>
                  </div>
                  <hr style={{ backgroundColor: "grey", height: "1px" }} />
                  <div className="Sear-visi-2-nys">
                    <h5>Solutions</h5>
                    <h6>Perimeter Protection</h6>
                    <h6>Information Display</h6>
                    <h6>SAR Live Interaction Solution - NYSTAI</h6>
                  </div>

                </>
              )}

            </div>

          </>
        )}
      </div>
      {/* <serch end */}

      {/* second nav start */}
      <nav class="navbar navbar-expand-lg bg-body-tertiary secondnav " id="navbar-solution-nys-secnav">
        <div class="container-fluid">
          <a class="navbar-brand" >
            <Link to="nystai-home">
              <img src={seclogo} class="img-fluid ms-3" style={{ height: "fit-content", width: "fit-content" }} alt="logo" />
            </Link>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class=" navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav me-auto my-2 navbar-nav-scroll m-auto gap-2" >
              <li class="nav-item">
                <a class="nav-link" ><Link to="nystai-product" className={`text-nav-topics-nystai ${isActive('/nystai-product')}`}>PRODUCTS </Link></a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" >  <Link to="nystai-solution" className={`text-nav-topics-nystai ${isActive('/nystai-solution')}`}>INTEGRATED SOLUTIONS</Link></a>
              </li>
              <li class="nav-item">
                <a class="nav-link active text-nav-topics-nystai" aria-current="page" >  PROTECT PLAN</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active text-nav-topics-nystai " aria-current="page" > SERVICE</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active." aria-current="page" ><Link to="nystai-support" className={`text-nav-topics-nystai ${isActive('/nystai-support')}`}
                > SUPPORT</Link></a>
              </li>
            </ul>

            <div class="d-flex mt-2" >
              <li class="nav-item me-5">
                <FontAwesomeIcon icon={faMagnifyingGlass} className="fa-search text-nav-topics-nystai" style={{ cursor: "pointer" }} onClick={toggleSearchBox} />
              </li>
              <li class="nav-item me-5">
                <p data-tooltip-id="my-tooltip" data-tooltip-content="contact"
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  data-tip="Sign In"
                >
                  <FontAwesomeIcon
                    icon={hovered ? faPhone : faWhatsapp}
                    className="text-nav-topics-nystai"
                  />
                </p>
                <Tooltip id="my-tooltip" place="bottom" className="tooltip" />
              </li>
              <li class="nav-item me-5">
                <FontAwesomeIcon icon={faShoppingBasket} bounce className="text-nav-topics-nystai" />
              </li>
            </div>
          </div>
        </div>
      </nav>
      {/* second nav end */}


      {/* mobile navbar start */}
      <div className=" nav-mobile-nystai " >
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <div className="">
              <Link to="/nystai-home"> <img src={nystailogo} className="img-fluid logo-nystai active" alt="NYSTAILOGO" /></Link>
            </div>
            <div className="">
              <div id="myNav" className={`overlay-mobile-nav ${navOpen ? 'open' : ''}`}>
                <p className="closebtn" onClick={closeNav}>&times;</p>
                <div className="container overlay-content">
                  <p class="nav-link  " > <Link to="/nystai-product">Products</Link></p>
                  <p class="nav-link " > <Link to="/nystai-solution"> INTEGRATED SOLUTIONS</Link></p>
                  <p class="nav-link "><Link to="/nystai-protplan">PROTECT PLAN</Link></p>
                  <p class="nav-link "><Link to="/nystai-services">SERVICE</Link></p>
                  <p className="nav-link "> <Link to="/nystai-support">SUPPORT</Link></p>
                  <div className=" right-ssl-icon-nystai ">
                    <div className="right-ssl-icon-nystai-number d-flex align-items-center justify-content-center">
                      <p className="text-number-nystai" href="tel:+91 81899 77700">  <FontAwesomeIcon icon={faPhone} style={{ color: '#1b6763', fontSize: "20px" }} className="me-2" />+91 81899 77700</p>
                    </div>
                    <div className="d-flex" >
                      <a href="https://wa.me/+918189977700" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSquareWhatsapp} shake style={{ color: '#0dc143' }}></FontAwesomeIcon></a>
                      <p><FontAwesomeIcon icon={faSearch} className=" " style={{ color: '#1b6763' }} /></p>
                      <p> <FontAwesomeIcon icon={faRightToBracket} className="" style={{ color: '#1b6763' }} /></p>
                      <p><Link to="/nystai-shopproduct"> <FontAwesomeIcon icon={faShoppingBasket} bounce className="" style={{ color: '#1b6763' }} /></Link></p>
                    </div>
                  </div>
                </div>
              </div>
              <span>
                <p onClick={openNav} style={{ fontSize: "20px", Color: "#1b6763" }}>Menu &#9776;</p>
              </span>
            </div>
          </div>
        </nav>
      </div >
      {/* mobile navbar end */}


      <Outlet />
    </>
  )
};

export default Layout;
