import React, { useEffect, useRef } from "react";
import './services.css';
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import video2 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/3.mp4';
import smarthome from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/smarthomevideo.mp4';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Power2 } from 'gsap';
import zoomimg from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppo-img-1.jpg';
import { motion, useScroll, useTransform } from "framer-motion";
import Footerproduct from "../A-LAYOUT/footer";
import pro1 from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/product-resize/door.png'
import paralaax from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/PARALLAX-1.png'
import paralaax2 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/PARALLAX-2.png'
import Layoutist from "./navbar (2)";








gsap.registerPlugin(ScrollTrigger);
AOS.init();


const data = {
    images: [
        require('../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppo-img-1.jpg'),
    ]
};

const cards = [
    {
        url: require('../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppo-img-1.jpg'),
        title: "Title 1",
        id: 1,
    },
    {
        url: require('../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppo-img-1.jpg'),
        title: "Title 2",
        id: 2,
    },
    {
        url: require('../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppo-img-1.jpg'),
        title: "Title 3",
        id: 3,
    },

];


export default function Demo() {
    const IntroVideoRef = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        if (videoRef.current) {
            videoRef.current.currentTime = 0;

            ScrollTrigger.create({
                trigger: IntroVideoRef.current,
                scrub: 1,
                pin: IntroVideoRef.current,
                start: "top top",
                end: "+=5000",
                onUpdate: (self) => {
                    if (videoRef.current) {
                        const scrollPos = self.progress;
                        const videoDuration = videoRef.current.duration;
                        const videoCurrentTime = videoDuration * scrollPos;

                        videoRef.current.currentTime = videoCurrentTime;
                    }
                }
            });
        }

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);


    const imgRef = useRef(null);

    useEffect(() => {
        let ctx = gsap.context(() => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: imgRef.current,
                    scrub: true,
                    start: 'top center',
                    markers: false,
                },
            });

            tl.to(imgRef.current, {
                scale: 1.22,
                duration: 2,
                ease: 'slow(0.2, 0.2, false)',
                transformOrigin: 'center center',
            });

            return () => {
                ctx.revert();
            };
        }, imgRef);
    }, []);




    const lineRef = useRef();
    const box01Ref = useRef();
    const box02Ref = useRef();
    const itemsRef = useRef([]);
    itemsRef.current = [];

    useEffect(() => {
        gsap.fromTo(
            box01Ref.current,
            {
                opacity: 0,
                clipPath: "polygon(0 0, 0% 0, 0 100%, 0 100%)",
                ease: Power2,
            },
            {
                opacity: 1,
                duration: 1,
                delay: 1,
                ease: Power2,
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
            }
        );
        console.log("itemsRef", itemsRef.current);
        itemsRef.current.map((item, index) => {
            gsap.fromTo(
                item,
                {
                    autoAlpha: 0,
                    scale: 1.5,
                    ease: Power2,
                    clipPath: "polygon(0 0, 0% 0, 0% 100%, 0 100%)"
                },
                {

                    duration: 0.5,
                    autoAlpha: 1,
                    scale: 1,
                    ease: Power2,
                    clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                    scrollTrigger: {
                        trigger: item,
                        start: "top center+=100",
                        toggleActions: "play none none reverse",

                    }
                }
            );
        });
    });
    const addToRefs = (item) => {
        if (item && !itemsRef.current.includes(item)) {
            console.log("item", item);
            itemsRef.current.push(item);
        }
    };



    return (
        <>
            <body>
                <section>
                    <Layoutist />
                </section>

                <section>
                    <div ref={IntroVideoRef} className="intro">
                        <video
                            id="video"
                            ref={videoRef}
                            src={video2}
                            muted
                            playsInline
                        ></video>
                    </div>
                </section>


                <section>
                    <section class="section top-section">
                        <div class="content-container content-theme-dark">
                            <div class="content-inner">
                                <div class="content-center">
                                    <img src={paralaax} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="section bottom-section">
                        <div class="content-container content-theme-light">
                            <div class="content-inner">
                                <div class="content-center">
                                    <img src={paralaax2} className="img-fluid" />

                                </div>
                            </div>
                        </div>
                    </section>
                </section>


                <section>
                    <section>
                        <div className="common-top-div" >

                            <h6 className="head-h-divtopic " >
                                <span style={{ color: "#1b6763" }}>NYST</span>AI-IoT
                            </h6>
                            <h3 className="head-h-divtopic " >

                            </h3>
                            <h6 className="sechead-h-divtop " >
                                The Maiden Unified platform for  Video + Alarm and Access control Solutions
                            </h6>

                        </div>
                    </section>

                    <div class="container  mb-5">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="card pro_card-des">
                                    <div class="image-container">
                                        <img src={pro1} class="img-fluid rounded thumbnail-image" />
                                    </div>
                                    <div class="product-detail-container ">
                                        <h6 class="">G4 Doorbell</h6>
                                        <p class="">UVC-G4-DoorBell </p>
                                        <p class="dress-name">WiFi-connected doorbell with an integrated night vision camera and porch light. </p>

                                        <div class="d-flex justify-content-between align-items-center  ">

                                            <h5 class="e">Wireless</h5>

                                            <div class="product-detail ">
                                                <button class="btn pulse">Shop Now</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3">
                                <div class="card pro_card-des">
                                    <div class="image-container">
                                        <img src={pro1} class="img-fluid rounded thumbnail-image" />
                                    </div>
                                    <div class="product-detail-container ">
                                        <h6 class="">G4 Doorbell</h6>
                                        <p class="">UVC-G4-DoorBell </p>
                                        <p class="dress-name">WiFi-connected doorbell with an integrated night vision camera and porch light. </p>

                                        <div class="d-flex justify-content-between align-items-center  ">

                                            <h5 class="e">Wireless</h5>

                                            <div class="product-detail ">
                                                <button class="btn pulse">Shop Now</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3">
                                <div class="card pro_card-des">
                                    <div class="image-container">
                                        <img src={pro1} class="img-fluid rounded thumbnail-image" />
                                    </div>
                                    <div class="product-detail-container ">
                                        <h6 class="">G4 Doorbell</h6>
                                        <p class="">UVC-G4-DoorBell </p>
                                        <p class="dress-name">WiFi-connected doorbell with an integrated night vision camera and porch light. </p>

                                        <div class="d-flex justify-content-between align-items-center  ">

                                            <h5 class="e">Wireless</h5>

                                            <div class="product-detail ">
                                                <button class="btn pulse">Shop Now</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3">
                                <div class="card pro_card-des">
                                    <div class="image-container">
                                        <img src={pro1} class="img-fluid rounded thumbnail-image" />
                                    </div>
                                    <div class="product-detail-container ">
                                        <h6 class="">G4 Doorbell</h6>
                                        <p class="">UVC-G4-DoorBell </p>
                                        <p class="dress-name">WiFi-connected doorbell with an integrated night vision camera and porch light. </p>

                                        <div class="d-flex justify-content-between align-items-center  ">

                                            <h5 class="e">Wireless</h5>

                                            <div class="product-detail ">
                                                <button class="btn pulse">Shop Now</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-3">
                                <div class="card pro_card-des">
                                    <div class="image-container">
                                        <img src={pro1} class="img-fluid rounded thumbnail-image" />
                                    </div>
                                    <div class="product-detail-container ">
                                        <h6 class="">G4 Doorbell</h6>
                                        <p class="">UVC-G4-DoorBell </p>
                                        <p class="dress-name">WiFi-connected doorbell with an integrated night vision camera and porch light. </p>

                                        <div class="d-flex justify-content-between align-items-center  ">

                                            <h5 class="e">Wireless</h5>

                                            <div class="product-detail ">
                                                <button class="btn pulse">Shop Now</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3">
                                <div class="card pro_card-des">
                                    <div class="image-container">
                                        <img src={pro1} class="img-fluid rounded thumbnail-image" />
                                    </div>
                                    <div class="product-detail-container ">
                                        <h6 class="">G4 Doorbell</h6>
                                        <p class="">UVC-G4-DoorBell </p>
                                        <p class="dress-name">WiFi-connected doorbell with an integrated night vision camera and porch light. </p>

                                        <div class="d-flex justify-content-between align-items-center  ">

                                            <h5 class="e">Wireless</h5>

                                            <div class="product-detail ">
                                                <button class="btn pulse">Shop Now</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3">
                                <div class="card pro_card-des">
                                    <div class="image-container">
                                        <img src={pro1} class="img-fluid rounded thumbnail-image" />
                                    </div>
                                    <div class="product-detail-container ">
                                        <h6 class="">G4 Doorbell</h6>
                                        <p class="">UVC-G4-DoorBell </p>
                                        <p class="dress-name">WiFi-connected doorbell with an integrated night vision camera and porch light. </p>

                                        <div class="d-flex justify-content-between align-items-center  ">

                                            <h5 class="e">Wireless</h5>

                                            <div class="product-detail ">
                                                <button class="btn pulse">Shop Now</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3">
                                <div class="card pro_card-des">
                                    <div class="image-container">
                                        <img src={pro1} class="img-fluid rounded thumbnail-image" />
                                    </div>
                                    <div class="product-detail-container ">
                                        <h6 class="">G4 Doorbell</h6>
                                        <p class="">UVC-G4-DoorBell </p>
                                        <p class="dress-name">WiFi-connected doorbell with an integrated night vision camera and porch light. </p>

                                        <div class="d-flex justify-content-between align-items-center  ">

                                            <h5 class="e">Wireless</h5>

                                            <div class="product-detail ">
                                                <button class="btn pulse">Shop Now</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                <section className="build-ur-systems-nys">
                    <div className="container text-center">
                        <h2>Build your Plans</h2>
                        <p>Find the ideal setup for your entire home.</p>
                        <button className="btn btn-outline-secondary">Take a Quiz</button>
                    </div>
                </section>

                <Footerproduct />
                
            </body >
        </>
    );
}



















































const HorizontalScrollCarousel = () => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    const x = useTransform(scrollYProgress, [0, 1], ["33.5%", "-33%"]);



    return (
        <section ref={targetRef} className="sec-horiscroll-nys">
            <div className="div-horiscroll-nys">
                <motion.div style={{ x }} className="flex gap-4">
                    {cards.map((card) => (
                        <Card card={card} key={card.id} />
                    ))}
                </motion.div>
            </div>
        </section>
    );
}

const Card = ({ card }) => {
    return (
        <>
            <div className="div-1-card-nys">
                <div className="div-3-card-nys">
                    <section className="container mb-5 sec-scroll-items-nys col-12">
                        <div className="row">
                            <div className="col-lg-4 div-1-scroll-items-nys">
                                <h4>Transparency<br />Mode !<br /><span className="pt-2">Keep Talking <br />Without Taking off <br />the Earbuds</span></h4>
                                <p className="pt-4">Transparency mode comes as standard <br /> with this flagship product. Simply touch <br /> and hold an earbud to switch between <br /> ANC and Transparency mode to talk with <br /> others.</p>
                            </div>
                            <div className="col-lg-8 p-4">
                                <img src={zoomimg} className="img-fluid image-1-card-items-nys" />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}