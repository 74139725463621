import { useEffect, useRef } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import oppo1 from "../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppo-img-1.jpg"
import oppo2 from "../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppoimg-3.jpg"
import banner1 from "../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/tl-pc-kv1@2x.jpg"
import cardimage1 from "../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/tl-pc-bf@2x.png"
import cardimage2 from "../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppoimg-5.png"
import cardimage3 from "../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/oppoimg-6.png"
import cctvcam1 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/cctv-1.png'
import opporezised1 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/Untitled design.png'
import { motion, useScroll, useTransform } from "framer-motion";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);



AOS.init();



const cards = [
  {
    url: oppo1,
    title: "Title 1",
    id: 1,
  },
  {
    url: oppo1,
    title: "Title 2",
    id: 2,
  },
  {
    url: oppo1,
    title: "Title 3",
    id: 3,
  },

];



export default function Layoutist() {



  useEffect(() => {
    gsap.to(".peliculas-bg", {
      backgroundColor: "#fff",
      scrollTrigger: {
        trigger: "#pelicula-2013",
        start: "top center",
        duration: 0.3,
        end: "bottom center",
        markers: false,
        toggleActions: "restart none restart pause"
      }
    });

    gsap.to(".peliculas-bg", {
      backgroundColor: "#222",
      scrollTrigger: {
        trigger: "#pelicula-2014",
        start: "top center",
        end: "bottom center",
        markers: false,
        toggleActions: "restart none restart pause"
      }
    });
  }, []);


  return (
    <>

      {/* banner */}



      {/* scroll animation */}
      <section className="peliculas-bg" >
        {/* non-color-background */}

        <div id="pelicula-2013" className="section1-smart">

          <div className="text-div-bgchange-nys" data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="100"
            data-aos-duration="2000">
            <section className="smarthomeisection2">
              <div>
                <h1>Welcome to the Future <br />The Smart Home</h1>
                <div className="line-smart"></div>
                <p>
                  Imagine a home where every device is interconnected and easily controlled through your voice or a simple tap on your phone. A smart home offers unparalleled convenience, energy efficiency, and enhanced security. From automated lighting and climate control to advanced security systems and smart appliances, your home adapts to your lifestyle,tasks effortless and enjoyable. Experience the future of living with a smart home that understands and anticipates your needs.
                </p>

              </div>

            </section>


            <div >
              <img src={opporezised1} className="img-fluid rounded-3" />
            </div>
          </div>
        </div>
        {/* color-background */}
        <HorizontalScrollCarousel />
      </section>

      {/* card Designs */}
      <section className="cards-product-nys " style={{ backgroundColor: "#f5f5f5" }}>


        <div className="card-1-prodpage-nys container" style={{ backgroundColor: "#fff", borderRadius: "10px" }} data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="100"
          data-aos-duration="2000" >
          <div className="col-12 row">
            <div className="col-lg-6 left-text-prodpage-nys">
              <h3>Pop-Open<br />
                <span >Open-Up Flash Connect</span></h3>
              <p className="mt-4">Once you've paired the earbuds with your device, they'll automatically connect when you open the case.</p>
            </div>
            <div className="col-lg-6 right-card-img-prodpage-nys">
              <img src={cardimage1} className="img-fluid w-100" />
            </div>
          </div>
        </div>



        <div className="card-2-prodpage-nys container mt-2" style={{ backgroundColor: "#fff" }} data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="100"
          data-aos-duration="2000">
          <div className="col-12 row">
            <div className="col-lg-6">
              <h3>Switch Between<br />
                Devices in an Instant<br />
                <span>With a Touch</span> </h3>
              <img src={cardimage2} className="img-fluid mt-5" style={{ borderRadius: "4px" }} />
            </div>
            <div className="col-lg-6">
              <p>The Enco Air2 Pro can be connected to two devices at the same time. Simply touch and hold an earbud to quickly switch between the devices.</p>
              <img src={cardimage3} className="img-fluid w-50" />
            </div>
          </div>
        </div>

        <section className="peliculas-bg pt-5 py-5" >
          {/* non-color-background */}

          <div id="pelicula-2013" className="section1-smart pt-5 py-5">

            <div className="text-div-bgchange-nys" data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="100"
              data-aos-duration="2000">
              <div className="card-2-prodpage-nys container mt-2" style={{ backgroundColor: "#fff" }} data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="100"
                data-aos-duration="2000">
                <div className="col-12 row">
                  <div className="col-lg-6">
                    <h3>Switch Between<br />
                      Devices in an Instant<br />
                      <span>With a Touch</span> </h3>
                  </div>
                  <div className="col-lg-6">
                    <p>The Enco Air2 Pro can be connected to two devices at the same time. Simply touch and hold an earbud to quickly switch between the devices.</p>
                  </div>

                  <center>
                    <img src={cctvcam1} className="img-fluid mt-4" />
                  </center>

                </div>
              </div>
            </div>
          </div>
          {/* color-background */}

        </section>





      </section>

      {/*image animation with text  */}
      <section className="img-anim-sec-nys" >
        <div className="">
          <h6 className="pb-3">Immersive Display, Redefined</h6>
          <h3 className="pb-3">Quad-Curved Infinite View Screen</h3>
          <p>Inbuilt shock absorption and cushioning are sealed around components to protect your phone's <br /> inner workings from drops and impacts. </p>
        </div>
        <center className=""
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="100"
          data-aos-duration="2000">
          <img src={cctvcam1} className="img-fluid mt-5" />
        </center>
      </section>

      {/* image mark */}
      <section className="">
        <div className="">

        </div>
      </section>


    </>
  );
}





const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["32%", "-35%"]);



  return (
    <section ref={targetRef} className="sec-horiscroll-nys">
      <div className="div-horiscroll-nys">
        <motion.div style={{ x }} className="flex gap-4">
          {cards.map((card) => (
            <Card card={card} key={card.id} />
          ))}
        </motion.div>
      </div>
    </section>
  );
}

const Card = ({ card }) => {
  return (
    <>
      <div className="div-1-card-nys">
        <div className="div-3-card-nys">
          <div id="pelicula-2014" className="section2-smart">
            <div className="container mb-5 mt-3 ">

              <div className="horiscrll-text-div-nys ">
                <h3 className="pb-4">Up to 28 Hours of Playback<br /> Listen to Your Heart's Content</h3>
                <p className="pb-3">A single charge is enough for 7 hours of listening time, and with the charging <br /> case, you can listen for up to 28 hours. Listen to your favorite music and play<br /> games without worrying about the battery life.</p>
              </div>
              <div className="horiscrll-img-div-nys">
                <img src={oppo2} className="img-fluid " style={{ borderRadius: "10px" }} />
              </div>

            </div>
          </div>
        </div>
      </div>




    </>
  );
}