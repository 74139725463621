import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBolt, faTowerBroadcast, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import Footerproduct from '../A-LAYOUT/footer';
import maincar1 from '../IMAGES-VIDEOS/C-IMG-SOLUTION-LAYOUT/solution-1 (1) (1).webm'
import maincar2 from '../IMAGES-VIDEOS/C-IMG-SOLUTION-LAYOUT/solution-1 (2) (1).webm'
import maincar3 from '../IMAGES-VIDEOS/C-IMG-SOLUTION-LAYOUT/solution-1 (3) (1).webm'
import prodimg from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/prod-img-2.png'
import { useLocation } from "react-router-dom";
import prodimg1 from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/prod-img-2.png';
import prodimg2 from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/prod-img-2.png';
import prodimg3 from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/prod-img-2.png';
import homenvrbanner from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/pro-banner-1.png';
import { categories, products } from '../C-PRODUCT LAYOUT/datass';
import Tabsliderpro from "./tab product";
import '../C-PRODUCT LAYOUT/category1.css'
const Category1 = () => {

  const [isSidebarHidden, setIsSidebarHidden] = useState(window.innerWidth < 768);
  const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarHidden(true);
      } else {
        setIsSidebarHidden(false);
      }
      if (window.innerWidth > 576) {
        setIsSearchFormVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);





  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialCategory = searchParams.get('category');
  const initialSubcategory = searchParams.get('subcategory');

  const [selectedCategory, setSelectedCategory] = useState(initialCategory || categories[0]?.id);
  const [selectedSubcategory, setSelectedSubcategory] = useState(initialSubcategory);

  useEffect(() => {
    if (initialCategory) {
      setSelectedCategory(initialCategory);
    }
    if (initialSubcategory) {
      setSelectedSubcategory(initialSubcategory);
    }
  }, [initialCategory, initialSubcategory]);

  const filteredProducts = selectedCategory
    ? products.filter(
      (product) =>
        product.category === parseInt(selectedCategory) &&
        (!selectedSubcategory || product.subcategory === parseInt(selectedSubcategory))
    )
    : products;


  const videoRef = useRef(null);

  const handleMouseOver = () => {
    videoRef.current.play();
  };

  const handleMouseOut = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const videoRef1 = useRef(null);

  const handleMouseOver1 = () => {
    videoRef1.current.play();
  };

  const handleMouseOut1 = () => {
    videoRef1.current.pause();
    videoRef1.current.currentTime = 0;

  };

  const videoRef2 = useRef(null);

  const handleMouseOver2 = () => {
    videoRef2.current.play();
  };

  const handleMouseOut2 = () => {
    videoRef2.current.play();
  };


  return (
    <>


      <body className="container-fluid">

        <section id="scrollspyHeading1">
          <div className=" mb-5 text-center">
            <section className="">
              <div className="common-top-div">

                <h6 className="head-h-divtopic " >
                  <span style={{ color: "#1b6763" }}>NYST</span>AI-IoT
                </h6>
                <h3 className="head-h-divtopic " >

                </h3>
                <h6 className="sechead-h-divtop " >
                  The  platform for  Video + Alarm and Access control Solutions
                </h6>

              </div>
            </section>
            <div className="col-12 d-flex justfy-center align-items">
              <img src={homenvrbanner} className="img-fluid" />
            </div></div>
        </section>

        <section id="scrollspyHeading2">
          <section class=" card-video-solu-nystai mt-5 mb-5">

            <h4 class=" h6-video-solu-nystai" >right monitoring.</h4>
            <h6 class=" h4-video-solu-nystai mb-5" >CONTINUE YOUR JOURNEY</h6>
            <div class="container">
              <div class="row row-cols-1 row-cols-md-3 g-4">
                <div class="col">
                  <div class="card">
                    <div class="card-img-top" style={{ height: "410px" }}>
                      <video
                        height="410" width="435"
                        autoPlay={false}
                        loop={true}
                        onMouseOver={handleMouseOver1}
                        onMouseOut={handleMouseOut1}
                        muted={true}
                        ref={videoRef1}
                        src={maincar1}
                      ></video>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">SMART HOME</h5>
                      <p class="card-text">
                        No additional IT infrastructure is required, which results in reduced CAPEX & OPEX costs.Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.</p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-img-top" style={{ height: "410px" }}>
                      <video
                        height="410" width="435"
                        autoPlay={false}
                        loop={true}
                        onMouseOver={handleMouseOver2}
                        onMouseOut={handleMouseOut2}
                        muted={true}
                        ref={videoRef2}
                        src={maincar2}
                      ></video>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title"> HOME SECURITY</h5>
                      <p class="card-text">
                        No additional IT infrastructure is required, which results in reduced CAPEX & OPEX costs.Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.</p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-img-top" style={{ height: "410px" }}>
                      <video
                        height="410" width="435"
                        autoPlay={false}
                        loop={true}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        muted={true}
                        ref={videoRef}
                        src={maincar3}
                      ></video>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">FIRE ALARM</h5>
                      <p class="card-text">
                        No additional IT infrastructure is required, which results in reduced CAPEX & OPEX costs.Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Tabsliderpro />

        </section>


        <div class="about-us-section" id="scrollspyHeading3">
          <section class=" " >
            <div className="row g-0" style={{ backgroundColor: "#222222" }}>
              <div className="col-lg-6 mb-3 mt-5 sticky-nys-solution-ser-left " >
                <div className=" mt-5 col-lg-8">
                  <h2 className="mb-4 sticky-nys-solution-ser-left-h2">TAILORED INDOOR CAR COVERS
                  </h2>
                  <p className="sticky-nys-solution-ser-left-p1">  Handmade to mirror the contours of your motor car, these tailored covers can be made from a palette of 18 colours, in single or two-tone combinations. Initials, names, crests, insignia or RR monograms can be selected to add a final, customised layer. </p>
                  <p className="mt-4 sticky-nys-solution-ser-left-p2">TAILORED INDOOR CAR COVERS</p>
                </div>
              </div>
              <div className="col-lg-6 sticky-nys-solution-right">
                <div className="image-container sticky-nys-solution-right-img">
                  <img src={prodimg1} alt="nys-img" className="nys-img" />
                  <div class="portfolio-hover-content">
                    <h1>Sensors</h1>
                  </div>
                </div>
                <div className="image-container sticky-nys-solution-right-img">
                  <img src={prodimg2} alt="nys-img" className="nys-img" />
                  <div class="portfolio-hover-content">
                    <h1>Digital Lock</h1>
                  </div>
                </div>
                <div className="image-container sticky-nys-solution-right-img">
                  <img src={prodimg3} alt="nys-img" className="nys-img" />
                  <div class="portfolio-hover-content">
                    <h1>Speakers</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section id="scrollspyHeading4" className="mt-4 mb-4">
          {selectedCategory && (
            <div className="subcategories-container">
              {categories.find((cat) => cat.id === parseInt(selectedCategory)).subcategories.map((subcategory) => (
                <div
                  key={subcategory.id}
                  className={`subcategory-card ${parseInt(selectedSubcategory) === subcategory.id ? 'selected' : ''}`}
                  onClick={() => setSelectedSubcategory(subcategory.id)}
                >
                  <button className="subcategory-name">{subcategory.name}</button>
                </div>
              ))}
            </div>
          )}
          <div className="row">
            {filteredProducts.map((values) => {
              const { id, title, Descriptipon1, Descriptipon2, Descriptipon3, image } = values;
              return (
                <div className="col-md-6 col-sm-12 col-xs-12 col-lg-4 col-xl-4 mb-4" key={id}>
                  <div className="container">
                    <div className="row pro-card-nystai-shop">
                      <Link to={`/product/${id}?category=${selectedCategory}&subcategory=${selectedSubcategory}`}>
                        <div className="card product-card-dropdown-nystai">
                          <div className="product-card-dropdown-nystai-img">
                            <img src={image} className="card-img-top mb-3" alt={title} />
                          </div>
                          <div className="">
                            <ul className="list-group">
                              <li className="list-group-item text-center" style={{ fontWeight: "bolder", fontSize: "2vh" }}>
                                {title}
                              </li>
                              <li className="list-group-item d-flex align-items-center">
                                <FontAwesomeIcon icon={faEye} className="me-2 font" />
                                <span>{Descriptipon1}</span>
                              </li>
                              <li className="list-group-item d-flex align-items-center">
                                <FontAwesomeIcon icon={faBolt} className="me-2 font" />
                                <span>{Descriptipon2}</span>
                              </li>
                              <li className="list-group-item d-flex align-items-center">
                                <FontAwesomeIcon icon={faTowerBroadcast} className="me-2 font" />
                                <span>{Descriptipon3}</span>
                              </li>
                              <li className="list-group-item text-center">
                                Learn More <FontAwesomeIcon icon={faAnglesRight} />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>


        <section id="scrollspyHeading5">
          <div className="Build-your-system-nys mt-5 mb-5 text-center">
            <h1>BUILD YOUR SYSTEM</h1>
          </div>
          <div className=" mt-5 mb-5">
            <img src={prodimg} className="img-fluid prod-img-2 mb-1" />
          </div>
        </section>

        <Footerproduct />

      </body>

    </>
  );
};

export default Category1;
