import React from "react";
import "./protplan.css";
import img2 from "../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/pro-banner-1.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUserShield } from '@fortawesome/free-solid-svg-icons';
import protectchart from '../IMAGES-VIDEOS/D-IMG-PLAN-LAYOUT/chart-668404e9b0d2f.webp';
import Footerproduct from "../A-LAYOUT/footer";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { Box } from "@mui/material";
gsap.registerPlugin(ScrollTrigger);


export default function Protectplan() {

    useEffect(() => {
        let ctx = gsap.context(() => {
            gsap.set(".photo:not(:first-child)", { opacity: 0, scale: 0.5 })

            const animation = gsap.to(".photo:not(:first-child)", {
                opacity: 1, scale: 1, duration: 1, stagger: 1
            })

            ScrollTrigger.create({
                trigger: ".gallery",
                start: "top top",
                end: "bottom bottom",
                pin: ".rightblock",
                animation: animation,
                scrub: true,
            })
        })
        return () => ctx.revert();
    }, [])



    return (
        <>







            <section className="Protect-plan-page-text-nys container">
                <div className="reveal-text">
                    <h2 className="mb-4" style={{ color: "#1b6763" }}>
                        Industry-Leading Solution Customized
                        <br />
                        to your Business
                    </h2>
                </div>
                <p>
                    Nystai's AcuSense technology embeds deep-learning algorithms into security cameras and video recorders to equip businesses and homeowners with smart tools
                    <br />
                    to take security to the next level, both indoors and outdoors.
                </p>
            </section>

            <section className="container">

                <section className="mb-5">
                    <div>
                        <img src={img2} className="img-fluid w-100 protect-plan-baner-nys" alt="Protect Plan Banner" />
                    </div>
                </section>

                <div className="row">
                    <div className="col-lg-5">
                        <div className="plan-content-nystai">
                            <h6 style={{ fontSize: "1.5rem", fontWeight: "500", lineHeight: "100%" }} className="mt-5 mb-4">Up to <span style={{ fontWeight: "700" }}>75%</span> off Web Hosting</h6>
                            <h1 style={{ fontSize: "2.8rem", fontWeight: "700", color: "#1b6763" }} className=" mb-4">Web Hosting As It Should Be:<br /> Fast and Secure
                            </h1>
                            <h6 style={{ fontSize: "1.2rem", fontWeight: "500" }} className="mt-2 mb-2 ">  <FontAwesomeIcon icon={faCheck} style={{
                                color: "#1b6763"
                            }} className="me-2" />  24/7 Customer Support</h6>
                            <h6 style={{ fontSize: "1.2rem", fontWeight: "500" }} className="mt-2 mb-2 ">  <FontAwesomeIcon icon={faCheck} style={{
                                color: "#1b6763"
                            }} className="me-2" />  Free Domain</h6>
                            <h6 style={{ fontSize: "1.2rem", fontWeight: "500" }} className="mt-2 mb-4 ">  <FontAwesomeIcon icon={faCheck} style={{
                                color: "#1b6763"
                            }} className="me-2" />  Free Website Migration
                            </h6>
                            <button className="btn btn-custom mt-2">Claim Deal</button>
                            <h5 className="mt-4 mb-5"><FontAwesomeIcon icon={faUserShield} style={{
                                color: "#1b6763"
                            }} className="me-2 mt-2" />30-Day Money-Back Guarantee</h5>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <img src={protectchart} className="img-fluid" />
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-5">
                        <div className="plan-content-nystai">
                            <h6 style={{ fontSize: "1.5rem", fontWeight: "500", lineHeight: "100%" }} className="mt-5 mb-4">Up to <span style={{ fontWeight: "700" }}>75%</span> off Web Hosting</h6>
                            <h1 style={{ fontSize: "2.8rem", fontWeight: "700", color: "#1b6763" }} className=" mb-4">Web Hosting As It Should Be:<br /> Fast and Secure
                            </h1>
                            <h6 style={{ fontSize: "1.2rem", fontWeight: "500" }} className="mt-2 mb-2 ">  <FontAwesomeIcon icon={faCheck} style={{
                                color: "#1b6763"
                            }} className="me-2" />  24/7 Customer Support</h6>
                            <h6 style={{ fontSize: "1.2rem", fontWeight: "500" }} className="mt-2 mb-2 ">  <FontAwesomeIcon icon={faCheck} style={{
                                color: "#1b6763"
                            }} className="me-2" />  Free Domain</h6>
                            <h6 style={{ fontSize: "1.2rem", fontWeight: "500" }} className="mt-2 mb-4 ">  <FontAwesomeIcon icon={faCheck} style={{
                                color: "#1b6763"
                            }} className="me-2" />  Free Website Migration
                            </h6>
                            <button className="btn btn-custom mt-2">Claim Deal</button>
                            <h5 className="mt-4 mb-5"><FontAwesomeIcon icon={faUserShield} style={{
                                color: "#1b6763"
                            }} className="me-2 mt-2" />30-Day Money-Back Guarantee</h5>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <img src={protectchart} className="img-fluid" />
                    </div>
                </div>

            </section>

            <section className="mb-5">
                <div>
                    <React.Fragment>
                        <Box className="gallery" sx={{ display: "flex" }}>
                            <Box className="left" sx={{
                                width: "50%",
                                marginLeft: "auto",
                                "& .details": {
                                    height: "100vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "40vw",
                                    marginLeft: "auto",
                                    color: "#000",
                                    fontSize: "1rem",
                                    fontWeight: 900,
                                }
                            }}>
                                <Box className="details">
                                    BRAND PRODUCT
                                    <p>
                                        Nystai's AcuSense technology embeds deep-learning algorithms into security cameras and video recorders to equip businesses and homeowners with smart tools
                                        to take security to the next level, both indoors and outdoors.
                                    </p>
                                </Box>
                                <Box className="details">
                                    PRODUCT DETAILS
                                    <p>
                                        Nystai's AcuSense technology embeds deep-learning algorithms into security cameras and video recorders to equip businesses and homeowners with smart tools
                                        to take security to the next level, both indoors and outdoors.
                                    </p>
                                </Box>
                                <Box className="details">
                                    DESIGN AGENCY
                                    <p>
                                        Nystai's AcuSense technology embeds deep-learning algorithms into security cameras and video recorders to equip businesses and homeowners with smart tools
                                        to take security to the next level, both indoors and outdoors.
                                    </p>
                                </Box>
                                <Box className="details">
                                    DESIGN AGENCY
                                    <p>
                                        Nystai's AcuSense technology embeds deep-learning algorithms into security cameras and video recorders to equip businesses and homeowners with smart tools
                                        to take security to the next level, both indoors and outdoors.
                                    </p>
                                </Box>
                                <Box className="details">
                                    DESIGN AGENCY
                                    <p>
                                        Nystai's AcuSense technology embeds deep-learning algorithms into security cameras and video recorders to equip businesses and homeowners with smart tools
                                        to take security to the next level, both indoors and outdoors.
                                    </p>
                                </Box>
                            </Box>
                            <Box className="rightblock" sx={{
                                width: "50%",
                                height: "100vh",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}>
                                <Box sx={{
                                    width: "40vw",
                                    height: "40vw",
                                    position: "relative",
                                    "& .photo": {
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        "& img": {
                                            height: "100%",
                                            width: "100%",
                                        }
                                    }
                                }}>
                                    <Box className="photo">
                                        <img
                                            src="http://static.showit.co/800/M0IzUCMvQCqlJn1YtNlikw/59514/pexels-yan-5793641-1.jpg"
                                            alt="img-1" />
                                    </Box>
                                    <Box className="photo">
                                        <img
                                            src="http://static.showit.co/800/137NFxuzSxeyjlons2WEzA/59514/pexels-yan-5793643.jpg"
                                            alt="img-2" />
                                    </Box>
                                    <Box className="photo">
                                        <img
                                            src="http://static.showit.co/800/3kOLYaOCS1SMieN6Y88Fqg/59514/mukuko-studio-mu88mlefcou-unsplash.jpg"
                                            alt="img-3" />
                                    </Box>
                                    <Box className="photo">
                                        <img
                                            src="http://static.showit.co/800/M0IzUCMvQCqlJn1YtNlikw/59514/pexels-yan-5793641-1.jpg"
                                            alt="img-1" />
                                    </Box>
                                    <Box className="photo">
                                        <img
                                            src="http://static.showit.co/800/137NFxuzSxeyjlons2WEzA/59514/pexels-yan-5793643.jpg"
                                            alt="img-2" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment >
                </div>
            </section>



            <section className="mb-5 container pb-5 pt-5">
                <div id="vehicle-details">
                    <div className="proComparisionContainer">
                        <h2 className="mb-5 text-center">Review our Protect plans.</h2>
                        <div className="proComparisionTable">
                            <table id="proComparisionTable" className="comparisionTable">
                                <tbody>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}></th>
                                        <td className="p-5" style={{ fontSize: "20px", fontWeight: "500", color: "#1b6763" }}>BASIC</td>
                                        <td className="p-5" style={{ fontSize: "20px", fontWeight: "500", color: "#1b6763" }}>PLUS</td>
                                        <td className="p-5" style={{ fontSize: "20px", fontWeight: "500", color: "#1b6763" }}>PRO</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>PRICE</th>
                                        <td>50B20L</td>
                                        <td>50B20L</td>
                                        <td>50B20L</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>Snapshot Capture</th>
                                        <td>197x129x227</td>
                                        <td>197x129x227</td>
                                        <td>197x129x227</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>Video History for up to 180 Days</th>
                                        <td>12</td>
                                        <td>12</td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>Person and Package Alerts</th>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>Rich Notification</th>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>Download up to 50 Videos At Once</th>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>24/7 Alarm Professional Monitoring</th>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>Alarm Cellular Backup</th>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th className="proHeading" style={{ fontSize: "17px", fontWeight: "300", borderRight: "1px solid #ddd" }}>Digital Security</th>
                                        <td>₹ 4,889</td>
                                        <td>₹ 4,889</td>
                                        <td>₹ 4,889</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 build-ur-systems-nys">
                <div className="container text-center">
                    <h2>Build your Plans</h2>
                    <p>Find the ideal setup for your entire home.</p>
                    <button className="btn btn-outline-secondary">Take a Quiz</button>
                </div>
            </section>

            <Footerproduct />
        </>
    );
}
