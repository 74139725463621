import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCog, faSignOutAlt, faBars, faSearch, faShoppingBasket, faPhone, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import '../C-PRODUCT LAYOUT/shopingcardpro.css';
import { Link, useLocation } from "react-router-dom";
import tri from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/Untitled-3.png';
import trilogo from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/nys logo  (2).png';
import { categories, products } from './datass';
import Category1 from "./ProductDetails";
import Servicesnystai from "../F-SERVICES-LAYOUT/Services";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Tooltip } from 'react-tooltip'

const AdminHub = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isSidebarHidden, setIsSidebarHidden] = useState(window.innerWidth < 768);
  const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]?.id);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarHidden(true);
      } else {
        setIsSidebarHidden(false);
      }
      if (window.innerWidth > 576) {
        setIsSearchFormVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const [searchVisible, setSearchVisible] = useState(false);
  const [searchVisible1, setSearchVisible1] = useState(false);

  const toggleSearchBox = () => {
    setSearchVisible(prevState => !prevState);
    setSearchVisible(!searchVisible);
  };
  const toggleSearchBox1 = () => {
    setSearchVisible1(prevState1 => (!prevState1));
  };


  const [hovered, setHovered] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const openNav = () => {
    setNavOpen(true);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleSidebar = () => {
    setIsSidebarHidden(!isSidebarHidden);
  };



  const toggleSearchForm = (e) => {
    if (window.innerWidth < 576) {
      e.preventDefault();
      setIsSearchFormVisible(!isSearchFormVisible);
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialCategory = searchParams.get('category');
  const initialSubcategory = searchParams.get('subcategory');

  useEffect(() => {
    if (initialCategory) {
      setSelectedCategory(initialCategory);
    }
    if (initialSubcategory) {
      setSelectedSubcategory(initialSubcategory);
    }
  }, [initialCategory, initialSubcategory]);

  useEffect(() => {
    const mainContent = document.querySelector('main');
    if (mainContent) {
      mainContent.scrollTo(0, 0);
    }
  }, [selectedCategory]);

  const renderCategoryComponent = () => {
    switch (parseInt(selectedCategory)) {
      case 1:
        return <Category1 />;
      case 2:
        return <Servicesnystai />;
      case 3:
        return <Category1 />;
      case 4:
        return <Category1 />;
      case 5:
        return <Category1 />;
      default:
        return <div>Please select a category</div>;
    }
  };

  return (
    <>

      <body className="pro-body">

        <section id="sidebar" className={isSidebarHidden ? 'hide' : ''}>
          <Link to="/nystai-home">
          <a  className="brand mt-3">
              <img src={tri} className="me-2" alt="Brand Logo" style={{width:"fit-content", height:"35px"}} />
              <img src={trilogo} alt="nys-logo" className="img-fluid nys-logo-side" style={{width:"fit-content", height:"30px"}}/>
            </a>
          </Link>

          <ul className="side-menu top">
            <li onClick={toggleSidebar}>
              <a >
                <FontAwesomeIcon icon={faBars} className=" dash-icon" />
                <span className="text" style={{ fontWeight: "bolder" }}>Product Category</span>
              </a>
            </li>
          </ul>

          <ul className="side-menu top">
            {categories.map((category) => (
              <li
                key={category.id}
                className={` ${parseInt(selectedCategory) === category.id ? 'active' : ''}`}
                onClick={() => {
                  setSelectedCategory(category.id);
                  setSelectedSubcategory(null);
                }}
              >
                <a>
                  <FontAwesomeIcon icon={category.icons} className="dash-icon" />
                  <span className="text">{category.name}</span>

                </a>
              </li>
            ))}
          </ul>

          <ul className="side-menu">
            <li>
              <a  className="logout">
                <FontAwesomeIcon icon={faCog} className="dash-icon" />
                <span className="text">Store</span>
              </a>
            </li>
            <li>
              <a  className="logout">
                <FontAwesomeIcon icon={faSignOutAlt} className="dash-icon" />
                <span className="text">Build your system</span>
              </a>
            </li>
          </ul>
        </section>

        <section id="content">
          <nav className="sticky-top ">

            <a  className="nav-link "><Link to="/nystai-product">PRODUCTS</Link></a>
            <a  className="nav-link"><Link to="/nystai-solution">INTEGRATED SOLUTIONS</Link></a>
            <a  className="nav-link">PROTECT PLAN</a>
            <a  className="nav-link">SERVICE</a>
            <a  className="nav-link"><Link to="/nystai-support" >SUPPORT</Link></a>

            <form action="#" className="ms-5">
              <div className={`form-input ${isSearchFormVisible ? 'show' : ''}`}>
                <input type="search" placeholder="Search..." />
                {/* <button type="submit" className="search-btn" onClick={toggleSearchForm}>
                  <FontAwesomeIcon icon={faSearch} className={isSearchFormVisible ? 'dash-icon-x' : 'dash-icon-search'} />
                </button> */}
              </div>
            </form>

            <input type="checkbox" id="switch-mode" hidden onChange={(e) => setIsDarkMode(e.target.checked)} />
            <label htmlFor="switch-mode" className="switch-mode"></label>
            {/* <FontAwesomeIcon icon={faMagnifyingGlass} className="fa-search icon-pro-font" style={{ cursor: "pointer" }} onClick={toggleSearchBox} /> */}
            <FontAwesomeIcon icon={faWhatsapp} className="icon-pro-font" />
            <Tooltip id="my-tooltip" place="bottom" className="tooltip" />
            <FontAwesomeIcon icon={faShoppingBasket} bounce className="icon-pro-font " />
          </nav>

          <main>

            <div className="head-title ">
              <div className="left">
                <ul className="breadcrumb">
                  <li>
                    <a  className="activeproname"> {products.name}</a>
                  </li>
                  <li><FontAwesomeIcon icon={faChevronRight} className=" dash-icon" /></li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading1">Overview</a>
                  </li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading2">Performance and benefits</a>
                  </li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading3">Application scenarios</a>
                  </li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading4">Product categories</a>
                  </li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading5">Build your Idea</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="container-fluid">
              {renderCategoryComponent()}
            </div>

          </main>

        </section>

      </body>
    </>
  );
};

export default AdminHub;
