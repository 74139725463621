import React, { useEffect, useRef, useState } from 'react';
import './product-page-03-css.css'
import plan1 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/755-450 (1).mp4'
import plan2 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/Access-Control-System-video.mp4'
import centerimg2 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/360 deg.png'
import centerimg4 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/800 MM.png'
import imgrightcard1 from "../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/img-video-card-imagee.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prodbg1 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/card-1.jpg'
import prodbg2 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/card-2.jpg'
import prodbg3 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/card-3.jpg'
import prodbg4 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/card-4.jpg'
import prodbg5 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/card-5.jpg'
import prodbg6 from '../IMAGES-VIDEOS/E-IMG-SERVICES-LAYOUT/card-6.jpg'
import gsap from 'gsap';
import AOS from 'aos';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import Demo22 from '../F-SERVICES-LAYOUT/demo';
gsap.registerPlugin(ScrollTrigger);
AOS.init();



export default function Demo2020() {
  const breakglassRef = useRef(null);
  const bglassLeftRef = useRef(null);
  const bglassRightRef = useRef(null);


  // splitimage
  useEffect(() => {
    // Select all elements with the class 'content'
    const sectionContent = document.querySelectorAll('.content');

    // Set initial styles for sectionContent
    gsap.set(sectionContent, {
      opacity: 0,
      y: 100
    });

    // Configuration for entering the viewport
    const enterConfig = { y: 0, opacity: 1, duration: 0.4 };

    // Create ScrollTrigger for each content section
    sectionContent.forEach((content) => {
      ScrollTrigger.create({
        trigger: content,
        onEnter: () => gsap.to(content, enterConfig),
        onEnterBack: () => gsap.to(content, enterConfig),
        onLeave: () => gsap.to(content, { y: -100, opacity: 0, duration: 0.4 }),
        onLeaveBack: () => gsap.to(content, { y: 100, opacity: 0, duration: 0.4 }),
        markers: false
      });
    });

    // Timeline for breakglass section animation
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: breakglassRef.current,
        start: "top 50%",
        end: "bottom 50%",
        scrub: true,
        markers: false
      }
    });

    // Add animations to the timeline
    tl.to(bglassLeftRef.current, { xPercent: -100, duration: 0.4 })
      .to(bglassRightRef.current, { xPercent: 100, duration: 0.4 }, 0); // Ensure both animations start at the same time

  }, []);




  // hide and zoom image
  useEffect(() => {
    const sections = document.querySelectorAll('.section');

    sections.forEach(section => {
      gsap.to(section, {
        scrollTrigger: {
          trigger: section,
          start: 'top center',
          end: 'bottom center',
          toggleClass: { targets: section, className: 'active' },
          markers: false,
          scrub: 3
        },
        opacity: 1,
        duration: 1,
      });

      gsap.from(section.querySelector('.section__img__inner'), {
        scrollTrigger: {
          trigger: section,
          start: 'top center',
          end: 'bottom center',
          scrub: 2,
        },
        // scale: 1.2,
      });
    });
  }, []);





  // imagezoom
  const imageRef = useRef(null);

  useEffect(() => {
    gsap.to(imageRef.current, {
      scale: 3,
      scrollTrigger: {
        trigger: imageRef.current,
        start: "top bottom", // When the top of the image hits the bottom of the viewport
        end: "top top", // When the top of the image hits the top of the viewport
        scrub: 3, // Smoothly animates the image during the scroll
        markers: false
      }
    });
  }, []);






  const [isModalOpen, setModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleVideoClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setModalOpen(false);
    }, 1000); // Match the duration of the slideDown animation
  };




  return (
    <>

      {/* <img src={banner1} className="img-fluid" /> */}
      <video src={plan2} autoPlay muted loop type="video/mp4" style={{ width: "100%", height: "100%" }} />













      {/* card section */}
      <section className="card-sec-main-nys container mb-5" >
        <div className="text-center mt-5 mb-3" data-aos="zoom-in-up"
          data-aos-anchor-placement="bottom-bottom" data-aos-delay="50"
          data-aos-duration="1000" >
          <h1>Product Highlights</h1>
        </div>
        <div className="row" data-aos="zoom-in-up"
          data-aos-anchor-placement="bottom-bottom" data-aos-delay="50"
          data-aos-duration="1000">

          <div className="col-lg-3 " >
            <img src={prodbg1} className="img-fluid imgggggg" style={{ borderRadius: "7px" }} />
            <div className=" rounded-3 mt-2 text-card-bg-nys zoom ">
              <p>33W SUPERVOOC</p>
            </div>
          </div>

          <div className="col-lg-6 " >
            <img src={prodbg2} className="img-fluid imgggggg" style={{ borderRadius: "7px" }} />
            <div className=" rounded-3 mt-2  text-card-bg-nys zoom">
              <p>36-Month Fluency Protection</p>
            </div>
          </div>

          <div className="col-lg-3" >
            <img src={prodbg3} className="img-fluid imgggggg" style={{ borderRadius: "7px" }} />
            <div className=" rounded-3 mt-2 text-card-bg-nys zoom">
              <p>90Hz Sunlight Display  </p>
            </div>
          </div>
        </div>
        <div className="row mt-3" data-aos="zoom-in-up"
          data-aos-anchor-placement="bottom-bottom" data-aos-delay="50"
          data-aos-duration="1000">

          <div className="col-lg-6 " >
            <img src={prodbg4} className="img-fluid imgggggg" style={{ borderRadius: "7px" }} />
            <div className=" rounded-3 mt-2  text-card-bg-nys zoom">
              <p>36-Month Fluency Protection</p>
            </div>
          </div>

          <div className="col-lg-3 " >
            <img src={prodbg5} className="img-fluid imgggggg" style={{ borderRadius: "7px" }} />
            <div className=" rounded-3 mt-2 text-card-bg-nys zoom ">
              <p>33W SUPERVOOC</p>
            </div>
          </div>

          <div className="col-lg-3" >
            <img src={prodbg6} className="img-fluid imgggggg" style={{ borderRadius: "7px" }} />
            <div className=" rounded-3 mt-2 text-card-bg-nys zoom">
              <p>90Hz Sunlight Display  </p>
            </div>
          </div>
        </div>
      </section>



      {/* video card text */}
      <section className="video-heading-nys" style={{ backgroundColor: "#000" }} >
        <div className="container" data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom" data-aos-delay="50"
          data-aos-duration="1000">
          <h2>Compact and Comfortable<br /><span>Fits Perfectly to Stay Comfortable All Day</span></h2>
          <p>Each earbud weighs only 4g, making it light and comfortable to wear. An ergonomic design fits snugly in the<br /> ear and ensures a more immersive experience.
          </p>

          <div className="row mt-5">
            <div className="col-lg-8 video-accesscon-left-nys">

              <div className="video-container">
                <div className="video-overlay" onClick={handleVideoClick}>
                  <video src={plan1} type="video/mp4" style={{ width: "100%", height: "100%" }} />
                  <div className="overlay-content ">
                    <p style={{ fontSize: "21px" }}><FontAwesomeIcon icon={faCirclePlay} />&nbsp;Play video</p>
                  </div>
                </div>

                {isModalOpen && (
                  <div className={`modal ${isClosing ? 'slide-down' : 'slide-up'}`} onClick={handleCloseModal}>
                    <span className="close" >&times;</span>
                    <div className="modal-content">
                      <video src={plan1} type="video/mp4" style={{ width: "100%" }} autoPlay />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 img-accesscon-right-nys">
              <img src={imgrightcard1} className="img-fluid" style={{ borderRadius: "7px", height: "100%" }} />
            </div>
          </div>
        </div>
      </section>



      {/* image spliting */}
      <div className="scroll-img-main-div container-fluid pt-5">
        <h6 className="pb-2">Lock screen clock styles</h6>
        <h2 className="pb-3">Your personality, leaping off the screen</h2>
        <p className="pb-3">Customise your lock screen clock style from three clock layouts, <br />eight font styles, and a smart colour selection </p>
        <section ref={breakglassRef} className="myTrigger mt-5 breakglass flex justify-content-center splitting-images-nys">
          <div className="breaking content">
            <div ref={bglassLeftRef} className="bglass-left"><img src={centerimg2} className="img-fluid" /></div>
            <div className="bglass-left"><img src={centerimg2} className="img-fluid" /></div>
            <div ref={bglassRightRef} className="bglass-right"><img src={centerimg2} className="" /></div>
          </div>
        </section>
      </div>




      {/* top text center image */}
      <section className="top-text-img-nys" style={{ backgroundColor: "#fff" }}>
        <div className=" container">
          <div className=" row">
            <div className="col-lg-7">
              <h2>5000mAh Large Battery<br />
                <span>Bigger Battery, Superior Power <br /> Saving </span></h2>
            </div>
            <div className="col-lg-5">
              <h6>Be free to roam without worrying about your phone. Big <br /> battery pairs with smart power-saving modes to banish <br />low battery fear.</h6>
            </div>
          </div>
          <div className="d-flex justify-content-center" data-aos="zoom-in-up"
            data-aos-anchor-placement="bottom-bottom" data-aos-delay="50"
            data-aos-duration="1000">
            <img src={centerimg4} className="img-fluid" />
          </div>
          <div className="d-flex justify-content-center mt-5 box-text-nys"   >
            <div className="col-lg-3" style={{ backgroundColor: "#eee", padding: "20px", borderRadius: "7px" }}>
              <h5>16 Hours<br /><span>Video</span></h5>
            </div>
            <div className="col-lg-3 ml-3" style={{ backgroundColor: "#eee", padding: "20px", borderRadius: "7px" }}>
              <h5>7 Hours<br /><span>Gaming</span></h5>
            </div>
          </div>

        </div>
      </section>




      {/* center-img-text */}

        <Demo22 />





    </>
  );
}





