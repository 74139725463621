import { faBolt, faCarBurst, faHandHoldingDroplet, faIndustry, faLandmark, faVideo } from "@fortawesome/free-solid-svg-icons";
import { faWandSparkles, faTowerBroadcast, faHouseSignal, faUsersViewfinder, faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { faGripfire } from '@fortawesome/free-brands-svg-icons';




export const categories = [
    {
        id: 1,
        name: ' HOME NVR & ALARM',
        category: 1,
        icons: faHouseSignal,
        subcategories: [

            { id: 1, name: 'NVR & ALARM' }
        ]
    },
    {
        id: 2,
        name: ' INDUSTRIAL ',
        category: 2,
        icons: faIndustry,
        subcategories: [
            { id: 1, name: 'INDUSTRIAL' }

        ]
    },
    {
        id: 3,
        name: 'CAMERA',
        category: 3,
        icons: faVideo,
        subcategories: [
            { id: 1, name: 'AI-Camera' },
            { id: 2, name: 'PIR-Camera' },
            { id: 3, name: 'PTZ-Camera' },
            { id: 4, name: 'DOM-Camera' },
            { id: 5, name: 'Bullet Camera' },
            { id: 6, name: 'Alarm Camera' },
            { id: 7, name: 'Solar Camera' },
            { id: 8, name: 'Wifi-Camera' },
            { id: 9, name: 'Panaromic Camera' },

        ]
    },
    {
        id: 4,
        name: ' SENSORS',
        category: 4,
        icons: faTowerBroadcast,
        subcategories: [
            { id: 1, name: 'Alarm Sensor' },
            { id: 2, name: 'Environmental Sensor' },
            { id: 3, name: 'Fire Sensor' },
            { id: 4, name: 'AI-Camera' },
            { id: 5, name: 'Perimeter Sensor' },

        ]
    },
    {
        id: 5,
        name: ' SMART SWITCH',
        category: 5,
        icons: faWandSparkles,
         subcategories: [
            { id: 1, name: 'SMART SWITCH' },

        ]
    },
    {
        id: 6,
        name: 'ACCESS CONTROL',
        category: 6,
        icons: faUsersViewfinder,
         subcategories: [
            { id: 1, name: 'ACCESS CONTROL' },

        ]
    },
    {
        id: 7,
        name: '  DIGITAL CLASS',
        category: 7,
        icons: faLandmark,
        subcategories: [
            { id: 1, name: ' DIGITAL CLASSROOM' },

        ]
    },
    {
        id: 8,
        category: 8,
        name: 'AI-VMS',
        icons: faCarBurst,
         subcategories: [
            { id: 1, name: 'AI-VMS' },

        ]
    },

    {
        id: 9,
        name: ' WATER ',
        category: 9,
        icons: faHandHoldingDroplet,
        subcategories: [
            { id: 1, name: 'Tank Manager' },

        ]
    },
    {
        id: 10,
        name: ' ELECTRIC FENCE',
        category: 10,
        icons: faBolt,
         subcategories: [
            { id: 1, name: 'ELECTRIC' },

        ]
    },
    {
        id: 11,
        name: '  FIRE SAFETY',
        category: 11,
        icons: faGripfire,
         subcategories: [
            { id: 1, name: 'Fire SAFETY' },

        ]
    },
    {
        id: 12,
        name: '  ACCESSORIES',
        category: 12,
        icons: faSnowflake,
         subcategories: [
            { id: 1, name: 'ACCESSORIES' },

        ]
    },
   



];

export const products = [


    {
        id: 1,
        category: 1,
        subcategory: 1,
        icons: faHouseSignal,

        title: "NVR & ALARM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/nvr-alarm-6683fb6ca7f08.webp"),
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/nvr-alarm-6683fb6ca7f08.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/nvr-alarm-6683fb6ca7f08.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/nvr-alarm-6683fb6ca7f08.webp"),


        Descriptipon1: "AI Function",
        Descriptipon2: "Monitoring & Remote Control",
        Descriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon1: "Intelligent analysis such as facial recognition, Video Analysis, flow statistics, and boundary crossing recognition",
        modalDescriptipon2: "Supports 32 group of personalized and multi-directional intelligent scene linkage",
        modalDescriptipon3: "Supports 64 FSK/LORA/ZIGBEE/Z-WAVE wireless devices & 16 IPC access for 5MP/4MP/3MP/2MP can be Connected",
        modalDescriptiponp: "NYSTAIoT NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM."
    },
    {
        id: 2,
        category: 1,
        subcategory: 1,


        title: " ALARM PANNEL",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-6683fb502f121.webp"),
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-6683fb502f121.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-6683fb502f121.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-6683fb502f121.webp"),


        Descriptipon1: "AI Function",
        Descriptipon2: "Monitoring & Remote Control",
        Descriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon1: "Intelligent analysis such as facial recognition, Video Analysis, flow statistics, and boundary crossing recognition",
        modalDescriptipon2: "Supports 32 group of personalized and multi-directional intelligent scene linkage",
        modalDescriptipon3: "Supports 64 FSK/LORA/ZIGBEE/Z-WAVE wireless devices & 16 IPC access for 5MP/4MP/3MP/2MP can be Connected",
        modalDescriptiponp: "NYSTAIoT NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM."
    },

    {
        id: 3,
        category: 1,
        subcategory: 1,

        title: "HOME NVR & ALARM",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-with-display-2-6683fb512a1fe.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-with-display-2-6683fb512a1fe.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-with-display-2-6683fb512a1fe.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "Monitoring & Remote Control",
        Descriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon1: "Intelligent analysis such as facial recognition, Video Analysis, flow statistics, and boundary crossing recognition",
        modalDescriptipon2: " Supports 32 group of personalized and multi-directional intelligent scene linkage",
        modalDescriptipon3: "Supports 64 FSK/LORA/ZIGBEE/Z-WAVE wireless devices & 16 IPC access for 5MP/4MP/3MP/2MP can be Connected",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM.",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-with-display-2-6683fb512a1fe.webp")
    },
    {
        id: 4,
        category: 1,
        subcategory: 1,

        title: "DOOR SENSOR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-1-6683fb550e5bc.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-1-6683fb550e5bc.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-1-6683fb550e5bc.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-1-6683fb550e5bc.webp")
    },
    {
        id: 5,
        category: 1,
        subcategory: 2,

        title: "Motion Sensor",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp")
    },
    {
        id: 6,
        category: 1,
        subcategory: 2,

        title: "EMERGENCY",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/sos-1-6683fbca468fe.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/sos-1-6683fbca468fe.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/sos-1-6683fbca468fe.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/sos-1-6683fbca468fe.webp")
    },
    {
        id: 7,
        category: 1,
        subcategory: 2,

        title: "EMERGENCY BUTTON",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "Control your system via your smartphone App",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp")
    },







    // id22222222222222222222222222222222


    {
        id: 8,
        category: 2,
        subcategory: 1,

        title: "INDUSTRIAL NVR  & ALARM",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/industrial-alarm-pannel-6683fb65404a8.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/industrial-alarm-pannel-6683fb65404a8.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/industrial-alarm-pannel-6683fb65404a8.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/industrial-alarm-pannel-6683fb65404a8.webp")
    },

    // id33333333333333333


    {
        id: 9,
        category: 3,
        subcategory: 1,


      
        icons1text: "AI Camera ",
        icons2text: "AI  Camera",
        icons3text: "AI Camera ",
        icons4text: "AI Camera",
        title: "AI Bullet HD Network Camera Outdoor ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb50e38d8.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb50e38d8.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb50e38d8.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb50e38d8.webp')


    },

    {
        id: 10,
        category: 3,
        subcategory: 1,

      
        title: "MOTION CAMER ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-2-6683fb523399b.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-2-6683fb523399b.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-2-6683fb523399b.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-2-6683fb523399b.webp')
    },
    {
        id: 11,
        category: 3,
        subcategory: 1,

      
        title: "MOTION CAMER ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-4-6683fb52c66d9.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-4-6683fb52c66d9.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-4-6683fb52c66d9.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-4-6683fb52c66d9.webp')
    },
    {
        id: 12,
        category: 3,
        subcategory: 1,

      
        title: "MOTION CAMER ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb526d569.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb526d569.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb526d569.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb526d569.webp')
    },
    {
        id: 13,
        category: 3,
        subcategory: 1,

      
        title: "MOTION CAMER ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-5-6683fb518dbfe.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-5-6683fb518dbfe.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-5-6683fb518dbfe.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-5-6683fb518dbfe.webp')
    },
    {
        id: 14,
        category: 3,
        subcategory: 1,

      
        title: "MOTION CAMER ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-6-6683fb5389df7.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-6-6683fb5389df7.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-6-6683fb5389df7.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-6-6683fb5389df7.webp')
    },
    {
        id: 15,
        category: 3,
        subcategory: 1,

      
        title: "AI Bullet HD Network Camera ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-7-6683fb53b9f8f.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-7-6683fb53b9f8f.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-7-6683fb53b9f8f.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-7-6683fb53b9f8f.webp')
    },
    {
        id: 16,
        category: 3,
        subcategory: 3,

      
        title: "AI Network HD Panoramic Camera",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-8-6683fb54526ca.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-8-6683fb54526ca.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-8-6683fb54526ca.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-8-6683fb54526ca.webp')
    },
    {
        id: 17,
        category: 3,
        subcategory: 3,

      
        title: "AI Network HD Panoramic Camera",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-9-6683fb545e0e5.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-9-6683fb545e0e5.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-9-6683fb545e0e5.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-9-6683fb545e0e5.webp')
    },
    {
        id: 18,
        category: 3,
        subcategory: 3,

      
        title: "MOTION CAMER ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/group-cameras-6683fb5f7cc60.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/group-cameras-6683fb5f7cc60.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/group-cameras-6683fb5f7cc60.webp'),
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/group-cameras-6683fb5f7cc60.webp')
    },
    {
        id: 19,
        category: 3,
        subcategory: 2,

      
        title: "MOTION CAMER ",
        Descriptipon2: "Supports TCP/IP+WiFi+4G network communication",
        Descriptipon1: "The main processor Fuhan Micro 6850",
        Descriptipon3: "Supports two-way voice intercom between phone and app",
        modalDescriptipon1: "Supports two-way voice intercom between phone and app",
        modalDescriptipon2: "Web backstage management based on HTML5, no need for plugins",
        modalDescriptipon3: "Supports 64 group of personalised and multi-directional intelligent scene linkage",
        modalDescriptiponp: "Intelligent analysis such as facial recognition, flow statistics, flame recognition,and boundary crossing recognition",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/group-cameras-2-6683fb60b3c61.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/group-cameras-2-6683fb60b3c61.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/group-cameras-2-6683fb60b3c61.webp'),
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/group-cameras-2-6683fb60b3c61.webp')
    },
    {
        id: 20,
        category: 3,
        subcategory: 1,

      
        title: "MOTION CAMER ",
        Descriptipon2: "Video Playback: SD card, PC, APP",
        Descriptipon1: "Processor: Dual-core A53",
        Descriptipon3: "Detection : Angle 90° | Radius 15 meters",
        modalDescriptipon1: "Video Playback: SD card, PC, APP",
        modalDescriptipon2: "Ultra-low power consumption, built-in large-capacity rechargeable lithium battery",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalimage1: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp'),
        modalimage2: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp'),
        modalimage3: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp'),
        modalDescriptiponp: "NYSTAI AI Guard Series AI video analysis + motion detection composite detector. It's like adding a pair of smart eyes and a smart brain to the detector, which is not only perceptible but also visible",
        image: require('../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp')
    },
    {
        id: 21,
        category: 3,
        subcategory: 2,

      
        title: "PIR Sensor  Integrated Camera",
        Descriptipon2: "Video Playback: SD card, PC, APP",
        Descriptipon1: "Processor: Dual-core A53",
        Descriptipon3: "Detection : Angle 90° | Radius 15 meters",
        modalDescriptipon1: "Video Playback: SD card, PC, APP",
        modalDescriptipon2: "Ultra-low power consumption, built-in large-capacity rechargeable lithium battery",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camer-gang-6683fb550e5af.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camer-gang-6683fb550e5af.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camer-gang-6683fb550e5af.webp"),
        modalDescriptiponp: "NYSTAI AI Guard Series AI video analysis + motion detection composite detector. It's like adding a pair of smart eyes and a smart brain to the detector, which is not only perceptible but also visible",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camer-gang-6683fb550e5af.webp")
    },
    {
        id: 22,
        category: 3,
        subcategory: 1,

      
        title: "OUTOOR MOTION CAMERA",
        Descriptipon2: "Video Playback: SD card, PC, APP",
        Descriptipon1: "Processor: Dual-core A53",
        Descriptipon3: "Detection : Angle 90° | Radius 15 meters",
        modalDescriptipon1: "Video Playback: SD card, PC, APP",
        modalDescriptipon2: "Ultra-low power consumption, built-in large-capacity rechargeable lithium battery",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb50e38d8.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb50e38d8.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb50e38d8.webp"),

        modalDescriptiponp: "NYSTAI AI Guard Series AI video analysis + motion detection composite detector. It's like adding a pair of smart eyes and a smart brain to the detector, which is not only perceptible but also visible",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-3-6683fb50e38d8.webp")
    },
    {
        id: 23,
        category: 3,
        subcategory: 1,

      
        title: "OUTDOOR INTRUDER CAMERA",
        Descriptipon2: "Video Playback: SD card, PC, APP",
        Descriptipon1: "Processor: Dual-core A53",
        Descriptipon3: "Detection : Angle 90° | Radius 15 meters",
        modalDescriptipon1: "Video Playback: SD card, PC, APP",
        modalDescriptipon2: "Ultra-low power consumption, built-in large-capacity rechargeable lithium battery",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-5-6683fb518dbfe.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-5-6683fb518dbfe.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-5-6683fb518dbfe.webp"),
        modalDescriptiponp: "NYSTAI AI Guard Series AI video analysis + motion detection composite detector. It's like adding a pair of smart eyes and a smart brain to the detector, which is not only perceptible but also visible",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/camera-5-6683fb518dbfe.webp")
    },
    {
        id: 24,
        category: 3,
        subcategory: 7,

      
        title: "SOLAR INTRUDER CAMERA",
        Descriptipon2: "Video Playback: SD card, PC, APP",
        Descriptipon1: "Processor: Dual-core A53",
        Descriptipon3: "Detection : Angle 90° | Radius 15 meters",
        modalDescriptipon1: "Video Playback: SD card, PC, APP",
        modalDescriptipon2: "Ultra-low power consumption, built-in large-capacity rechargeable lithium battery",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/solar-intruder-camera-6683fbc945981.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/solar-intruder-camera-6683fbc945981.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/solar-intruder-camera-6683fbc945981.webp"),
        modalDescriptiponp: "NYSTAI AI Guard Series AI video analysis + motion detection composite detector. It's like adding a pair of smart eyes and a smart brain to the detector, which is not only perceptible but also visible",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/solar-intruder-camera-6683fbc945981.webp")
    },




    // id444444444444444444444
    {
        id: 25,
        category: 4,
        subcategory: 1,


        title: "DOOR SENSOR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-1-6683fb550e5bc.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-1-6683fb550e5bc.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-1-6683fb550e5bc.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-1-6683fb550e5bc.webp")
    },

    {
        id: 26,
        category: 4,
        subcategory: 2,


        title: "DOOR SENSORS",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-2-6683fb55ab52d.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-2-6683fb55ab52d.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-2-6683fb55ab52d.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/door-sensor-2-6683fb55ab52d.webp")
    },
    {


        id: 27,
        category: 4,
        subcategory: 1,


        title: "WINDOW SENSOR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-1-6683fbd3e8d50.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-1-6683fbd3e8d50.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-1-6683fbd3e8d50.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-1-6683fbd3e8d50.webp")
    },
    {


        id: 284,
        category: 4,
        subcategory: 1,


        title: "WINDOW SENSOR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-2-6683fbd407445.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-2-6683fbd407445.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-2-6683fbd407445.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-2-6683fbd407445.webp")
    },
    {


        id: 29,
        category: 4,
        subcategory: 1,


        title: "WINDOW SENSOR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-3-6683fbd4d91da.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-3-6683fbd4d91da.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-3-6683fbd4d91da.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/window-sensor-3-6683fbd4d91da.webp")
    },
    {


        id: 30,
        category: 4,
        subcategory: 1,


        title: "Rolling Shutter Sensor",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rolling-shutter-sensor-1-6683fbc16efdd.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rolling-shutter-sensor-1-6683fbc16efdd.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rolling-shutter-sensor-1-6683fbc16efdd.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rolling-shutter-sensor-1-6683fbc16efdd.webp")
    },
    {


        id: 31,
        category: 4,
        subcategory: 1,


        title: "Rolling Shutter Sensor",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rolling-shutter-sensor-2-6683fbc19bbe6.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rolling-shutter-sensor-2-6683fbc19bbe6.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rolling-shutter-sensor-2-6683fbc19bbe6.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Low power consumption design",
        modalDescriptipon2: " Automatic status report",
        modalDescriptipon3: " Easy installation",
        modalDescriptiponp: "Anti-Tamper alarm",

        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rolling-shutter-sensor-2-6683fbc19bbe6.webp")
    },
    {


        id: 32,
        category: 4,
        subcategory: 3,


        title: "Wireless Smoke Sensor",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-1-6683fbc620d69.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-1-6683fbc620d69.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-1-6683fbc620d69.webp"),

        Descriptipon1: "360° induction detection",
        Descriptipon2: "Intelligent linkage alarm",
        Descriptipon3: "Low power consumption CMOS microprocessor",
        modalDescriptipon1: "High decibel on-site sound and light alarm",
        modalDescriptipon2: "With self-test, manual test and reset functions",
        modalDescriptipon3: "Metal shielding cover, anti-high frequency interference",
        modalDescriptiponp: "Dust-proof, insect-proof, and resistant to external light interference",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-1-6683fbc620d69.webp")
    },
    {


        id: 33,
        category: 4,
        subcategory: 3,


        title: "SMOKE SENSOR WITH MOTION DETECTION ",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-3-6683fbc717b05.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-3-6683fbc717b05.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-3-6683fbc717b05.webp"),
        Descriptipon1: "360° induction detection",
        Descriptipon2: "Intelligent linkage alarm",
        Descriptipon3: "Low power consumption CMOS microprocessor",
        modalDescriptipon1: "High decibel on-site sound and light alarm",
        modalDescriptipon2: "With self-test, manual test and reset functions",
        modalDescriptipon3: "Metal shielding cover, anti-high frequency interference",
        modalDescriptiponp: "Dust-proof, insect-proof, and resistant to external light interference",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-3-6683fbc717b05.webp")
    },
    {


        id: 34,
        category: 4,
        subcategory: 3,


        title: "CO2 SENSOR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-5-6683fbc83e0b3.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-5-6683fbc83e0b3.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-5-6683fbc83e0b3.webp"),
        Descriptipon1: "360° induction detection",
        Descriptipon2: "Intelligent linkage alarm",
        Descriptipon3: "Low power consumption CMOS microprocessor",
        modalDescriptipon1: "High decibel on-site sound and light alarm",
        modalDescriptipon2: "With self-test, manual test and reset functions",
        modalDescriptipon3: "Metal shielding cover, anti-high frequency interference",
        modalDescriptiponp: "Dust-proof, insect-proof, and resistant to external light interference",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-5-6683fbc83e0b3.webp")
    },
    {


        id: 35,
        category: 4,
        subcategory: 3,


        title: "GAS SENSOR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/gas-sensor-1-6683fb5f57d6a.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/gas-sensor-1-6683fb5f57d6a.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/gas-sensor-1-6683fb5f57d6a.webp"),
        Descriptipon1: "360° induction detection",
        Descriptipon2: "Intelligent linkage alarm",
        Descriptipon3: "Low power consumption CMOS microprocessor",
        modalDescriptipon1: "High decibel on-site sound and light alarm",
        modalDescriptipon2: "With self-test, manual test and reset functions",
        modalDescriptipon3: "Metal shielding cover, anti-high frequency interference",
        modalDescriptiponp: "Dust-proof, insect-proof, and resistant to external light interference",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/gas-sensor-1-6683fb5f57d6a.webp")
    },
    {


        id: 36,
        category: 4,
        subcategory: 3,


        title: "SMOKE AND FIRE SENSOR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-1-6683fbc620d69.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-1-6683fbc620d69.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-1-6683fbc620d69.webp"),
        Descriptipon1: "360° induction detection",
        Descriptipon2: "Intelligent linkage alarm",
        Descriptipon3: "Low power consumption CMOS microprocessor",
        modalDescriptipon1: "High decibel on-site sound and light alarm",
        modalDescriptipon2: "With self-test, manual test and reset functions",
        modalDescriptipon3: "Metal shielding cover, anti-high frequency interference",
        modalDescriptiponp: "Dust-proof, insect-proof, and resistant to external light interference",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-1-6683fbc620d69.webp")
    },
    {


        id: 37,
        category: 4,
        subcategory: 3,


        title: "SMOKE AND FIRE SENSO4",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-2-6683fbc6deb1e.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-2-6683fbc6deb1e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-2-6683fbc6deb1e.webp"),
        Descriptipon1: "360° induction detection",
        Descriptipon2: "Intelligent linkage alarm",
        Descriptipon3: "Low power consumption CMOS microprocessor",
        modalDescriptipon1: "High decibel on-site sound and light alarm",
        modalDescriptipon2: "With self-test, manual test and reset functions",
        modalDescriptipon3: "Metal shielding cover, anti-high frequency interference",
        modalDescriptiponp: "Dust-proof, insect-proof, and resistant to external light interference",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smoke-sensor-2-6683fbc6deb1e.webp")
    },
    {


        id: 38,
        category: 4,
        subcategory: 1,


        title: "Microwave radar detector",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/microwaverador-sensor-1-6683fb6ae1f6e.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/microwaverador-sensor-1-6683fb6ae1f6e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/microwaverador-sensor-1-6683fb6ae1f6e.webp"),
        Descriptipon3: "Intruder detection Microwave Doppler radar motion sensor ",
        Descriptipon2: "High sensitivity, fast and accurate response.",
        Descriptipon1: "Sensitivity and angle adjustable",
        modalDescriptipon1: "Sensitivity and angle adjustable",
        modalDescriptipon2: "High sensitivity, fast and accurate response.",
        modalDescriptipon3: "Intruder detection Microwave Doppler radar motion sensor",
        modalDescriptiponp: "Awesome weather interference resistant, wide working condition",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/microwaverador-sensor-1-6683fb6ae1f6e.webp")
    },

    {


        id: 39,
        category: 4,
        subcategory: 2,


        title: "Environment",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/environment-sensor-1-6683fb5e5128b.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/environment-sensor-1-6683fb5e5128b.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/environment-sensor-1-6683fb5e5128b.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/environment-sensor-1-6683fb5e5128b.webp")
    },
    {


        id: 40,
        category: 4,
        subcategory: 1,


        title: "Vibration sensor",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vibrator-sensor-1-6683fbcb28411.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vibrator-sensor-1-6683fbcb28411.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vibrator-sensor-1-6683fbcb28411.webp"),
        Descriptipon3: "Low power consumption design",
        Descriptipon2: "Automatic status report",
        Descriptipon1: "Easy installation",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vibrator-sensor-1-6683fbcb28411.webp")
    },
    // perimeterrrrr
    {


        id: 41,
        category: 4,
        subcategory: 5,


        title: "NI-IRB2 Digital Active Infrared Fence",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-2-6683fbbdc6590.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-2-6683fbbdc6590.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-2-6683fbbdc6590.webp"),
        Descriptipon3: "Patented balanced checking, Waterproof grade IP65.",
        Descriptipon2: "Detachable dual-system, programmable multi modes",
        Descriptipon1: "Wired/BUS compatible",
        modalDescriptipon1: "Patented balanced checking, Waterproof grade IP65.",
        modalDescriptipon2: "Detachable dual-system, programmable multi modes",
        modalDescriptipon3: "Intelligent power transmission, self-adaptation in environment",
        modalDescriptiponp: "Mutual-transmission and relay style infrared receiving and transmitting modes are optional;",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-2-6683fbbdc6590.webp")
    },
    {


        id: 42,
        category: 4,
        subcategory: 5,


        title: "NI-IRB3 Digital Active Infrared Fence",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-3-6683fbbdc8c64.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-3-6683fbbdc8c64.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-3-6683fbbdc8c64.webp"),
        Descriptipon3: "Patented balanced checking, Waterproof grade IP65.",
        Descriptipon2: "Detachable dual-system, programmable multi modes",
        Descriptipon1: "Wired/BUS compatible",
        modalDescriptipon1: "Patented balanced checking, Waterproof grade IP65.",
        modalDescriptipon2: "Detachable dual-system, programmable multi modes",
        modalDescriptipon3: "Intelligent power transmission, self-adaptation in environment",
        modalDescriptiponp: "Mutual-transmission and relay style infrared receiving and transmitting modes are optional;",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-3-6683fbbdc8c64.webp")
    },
    {


        id: 43,
        category: 4,
        subcategory: 5,


        title: "NI-IRB4 Digital Active Infrared Fence",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-4-6683fbbed697e.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-4-6683fbbed697e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-4-6683fbbed697e.webp"),
        Descriptipon3: "Patented balanced checking, Waterproof grade IP65.",
        Descriptipon2: "Detachable dual-system, programmable multi modes",
        Descriptipon1: "Wired/BUS compatible",
        modalDescriptipon1: "Patented balanced checking, Waterproof grade IP65.",
        modalDescriptipon2: "Detachable dual-system, programmable multi modes",
        modalDescriptipon3: "Intelligent power transmission, self-adaptation in environment",
        modalDescriptiponp: "Mutual-transmission and relay style infrared receiving and transmitting modes are optional;",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-4-6683fbbed697e.webp")
    },
    {


        id: 44,
        category: 4,
        subcategory: 5,


        title: "NI-IRB6 Digital Active Infrared Fence",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-6-6683fbbed468c.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-6-6683fbbed468c.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-6-6683fbbed468c.webp"),
        Descriptipon3: "Patented balanced checking, Waterproof grade IP65.",
        Descriptipon2: "Detachable dual-system, programmable multi modes",
        Descriptipon1: "Wired/BUS compatible",
        modalDescriptipon1: "Patented balanced checking, Waterproof grade IP65.",
        modalDescriptipon2: "Detachable dual-system, programmable multi modes",
        modalDescriptipon3: "Intelligent power transmission, self-adaptation in environment",
        modalDescriptiponp: "Mutual-transmission and relay style infrared receiving and transmitting modes are optional;",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/perimeter-beem-6-6683fbbed468c.webp")
    },




    //55555555555555555555555
    {
        id: 45,
        category: 5,
        subcategory: 1,

        title: "SMART TOUCH SWITCH",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-switch-1-6683fbc5acce0.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-switch-1-6683fbc5acce0.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-switch-1-6683fbc5acce0.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "Working - When the combustible gas concentration reaches the alarm value, the red alarm indicator keeps flashing and the Alarm keeps 1 sound ﻿per second. When the concentration of carbon monoxide reaches the alarm value, the red alarm indicator is kept on light, the Alarm keeps 2 sounds per second.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-switch-1-6683fbc5acce0.webp")
    },
    {
        id: 46,
        category: 5,
        subcategory: 1,

        title: " GANG SWITCH",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/gang-switch-2-6683fb5e32b47.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/gang-switch-2-6683fb5e32b47.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/gang-switch-2-6683fb5e32b47.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "Working - When the combustible gas concentration reaches the alarm value, the red alarm indicator keeps flashing and the Alarm keeps 1 sound ﻿per second. When the concentration of carbon monoxide reaches the alarm value, the red alarm indicator is kept on light, the Alarm keeps 2 sounds per second.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/gang-switch-2-6683fb5e32b47.webp")
    },
    {
        id: 47,
        category: 5,
        subcategory: 1,

        title: "SMART PLUG",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-plug-3-6683fbc4033df.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-plug-3-6683fbc4033df.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-plug-3-6683fbc4033df.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "Working - When the combustible gas concentration reaches the alarm value, the red alarm indicator keeps flashing and the Alarm keeps 1 sound ﻿per second. When the concentration of carbon monoxide reaches the alarm value, the red alarm indicator is kept on light, the Alarm keeps 2 sounds per second.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-plug-3-6683fbc4033df.webp")
    },
    {

        id: 48,
        category: 5,


        title: "WIRELESS DOOR BELL",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-plug-3-6683fbc4033df.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-plug-3-6683fbc4033df.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-plug-3-6683fbc4033df.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-plug-3-6683fbc4033df.webp")
    },


    {
        id: 49,
        category: 5,


        title: "WIRELESS DOOR BELL",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-switch-5-6683fbc4ba27e.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-switch-5-6683fbc4ba27e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-switch-5-6683fbc4ba27e.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/smart-switch-5-6683fbc4ba27e.webp")
    },




    //666666666666666666666
    {
        id: 50,
        category: 6,
        subcategory: 1,

        title: "ACCESS CONTROLS",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-1-6683fb4f64d60.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-1-6683fb4f64d60.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-1-6683fb4f64d60.webp"),
        Descriptipon1: " AI face recognition",
        Descriptipon2: "System integration",
        Descriptipon3: "Interface & Integration Support",
        modalDescriptipon1: "The host runs the Linux operating system, integrating access control management, ",
        modalDescriptipon2: "Rich interface Supports SDK under multiple platforms such as Windows/ Linux and HTTP protocol.",
        modalDescriptipon3: " The host runs the Linux operating system, integrating access control management, attendance management, facial recognition, and card swiping.",
        modalDescriptiponp: "Double-eyed live test, unique face recognition algorithm, card swiping and other high-precision verification methods to compare personnel authority.High capacity Using deep learning algorithms to support the maximum face comparison library and 80,000 identification records.Multi-mode fusion Human face can be combined with a variety of means such as passwords, cards, and QR codes to achieve a more secure passage.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-1-6683fb4f64d60.webp")
    },
    {
        id: 51,
        category: 6,
        subcategory: 1,

        title: "ACCESS CONTROLS",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-2-6683fb4f62250.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-2-6683fb4f62250.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-2-6683fb4f62250.webp"),
        Descriptipon1: " AI face recognition",
        Descriptipon2: "System integration  ",
        Descriptipon3: "High Definition Multi Touch Display",
        modalDescriptipon1: "System integration",
        modalDescriptipon2: "Rich interface Supports SDK under multiple platforms such as Windows/ Android and HTTP protocol.",
        modalDescriptipon3: " The host runs the Android operating system, integrating access control management, attendance management, facial recognition, and card swiping.",
        modalDescriptiponp: "Double-eyed live test, unique face recognition algorithm, card swiping and other high-precision verification methods to compare personnel authority.High capacity Using deep learning algorithms to support the maximum face comparison library and 80,000 identification records.Multi-mode fusion Human face can be combined with a variety of means such as passwords, cards, and QR codes to achieve a more secure passage.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-2-6683fb4f62250.webp")
    },
    {
        id: 52,
        category: 6,
        subcategory: 1,

        title: "ACCESS CONTROLS",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-3-6683fb5025298.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-3-6683fb5025298.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-3-6683fb5025298.webp"),
        Descriptipon1: " AI face recognition",
        Descriptipon2: "System integration  ",
        Descriptipon3: "High Definition Multi Touch Display",
        modalDescriptipon1: "System integration",
        modalDescriptipon2: "Rich interface Supports SDK under multiple platforms such as Windows/ Android and HTTP protocol.",
        modalDescriptipon3: " The host runs the Android operating system, integrating access control management, attendance management, facial recognition, and card swiping.",
        modalDescriptiponp: "Double-eyed live test, unique face recognition algorithm, card swiping and other high-precision verification methods to compare personnel authority.High capacity Using deep learning algorithms to support the maximum face comparison library and 80,000 identification records.Multi-mode fusion Human face can be combined with a variety of means such as passwords, cards, and QR codes to achieve a more secure passage.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/access-control-3-6683fb5025298.webp")
    },

    //77777777777777777777777777



    {
        id: 23,
        category: 7,
        subcategory: 1,

        title: "INTERACTIVE FLAT PANEL DISPLAY (IFPD)",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/ifpd-1-6683fb60c8885.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/ifpd-1-6683fb60c8885.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/ifpd-1-6683fb60c8885.webp"),
        Descriptipon3: "1mm detection precision Support 20-points multi touch & writing",
        Descriptipon1: "3G + 32G  Large memory large space",
        Descriptipon2: "Android 8.0 Improve overall response speed",
        modalDescriptipon1: "IFPD can provide high-quality and high-resolution images and text, which can improve the clarity and readability of the content.",
        modalDescriptipon2: "Built in Camera & Microphone Faster for conference solution",
        modalDescriptipon3: "IFPD can offer high interactivity and functionality, which can enhance the engagement and participation of the students. Teachers and students can use multiple touch points and gestures, such as zoom, drag, rotate, and swipe, to manipulate the content.",
        modalDescriptiponp: "An Interactive Flat-Panel Display (IFPD) is a large-format touchscreen display ideal for meeting rooms and collaborative spaces. It is a replacement for clunky or outdated projector technology with a higher-quality display, enhanced connectivity, and built-in software solutions.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/ifpd-1-6683fb60c8885.webp")
    },




    // 88888888888888888888888888888888888
    {
        id: 54,
        category: 8,
        subcategory: 1,

        title: "BSD System With Camera & Monitor",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-1-6683fbcc82476.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-1-6683fbcc82476.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-1-6683fbcc82476.webp"),
        Descriptipon2: "AHD1080P//960P/720P BSD Camera",
        Descriptipon1: "4-pin air port",
        Descriptipon3: "AHD 7-inch Vehicle Mounted Display (Detachable sunshade) Resolution:1024*R.G.B*600 Brightness：LED，500-650cd/㎡",
        modalDescriptipon1: "The prompt language can be customised",
        modalDescriptipon2: "3 ways Trig Trigger priority:V2>V1>V3",
        modalDescriptiponP: "Audio Input：Built-in speaker，1 way audio input(V2)",
        modalDescriptipon3: "Video recording function",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-1-6683fbcc82476.webp")
    },
    {
        id: 55,
        category: 8,
        subcategory: 1,

        title: "ADAS",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-2-6683fbcc8229e.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-2-6683fbcc8229e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-2-6683fbcc8229e.webp"),
        Descriptipon2: "AHD1080P//960P/720P BSD Camera",
        Descriptipon1: "Video recording 1CH/3CH AHD 720P/1080P",
        Descriptipon3: "DSM with AI algorithm ,detect and alert drowsiness, distraction, smoking, phone calling, and more.",
        modalDescriptipon1: "ADAS includes Forward Collision Warning (FCW), Lane Departure  Warning (LDW), Pedestrian Detection (PD), and Vehicle Proximity  Alert",
        modalDescriptipon2: "3 ways Trig Trigger priority:V2>V1>V3",
        modalDescriptipon3: "BSD function employs real-time intelligent detection of pedestrians and cyclists alongside the vehicle, providing timelywarnings to the driver.",
        modalDescriptiponp: "CMS It is a centralised monitoring and alarm system. Through  a 4G network, it real-time transmits detailed information exterior conditions, the driver's work status, and any unforeseen  events to the alarm center.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-2-6683fbcc8229e.webp")
    },
    {
        id: 56,
        category: 8,
        subcategory: 1,

        title: "MDVR",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-5-6683fbcfafa62.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-6-6683fbd014456.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-3-6683fbce1620e.webp"),
        Descriptipon2: "AHD1080P//960P/720P BSD Camera",
        Descriptipon1: "Video recording 1CH/3CH AHD 720P/1080P",
        Descriptipon3: "DSM with AI algorithm ,detect and alert drowsiness, distraction, smoking, phone calling, and more.",
        modalDescriptipon1: "ADAS includes Forward Collision Warning (FCW), Lane Departure  Warning (LDW), Pedestrian Detection (PD), and Vehicle Proximity  Alert",
        modalDescriptipon2: "3 ways Trig Trigger priority:V2>V1>V3",
        modalDescriptipon3: "BSD function employs real-time intelligent detection of pedestrians and cyclists alongside the vehicle, providing timelywarnings to the driver.",
        modalDescriptiponp: "CMS It is a centralised monitoring and alarm system. Through  a 4G network, it real-time transmits detailed information exterior conditions, the driver's work status, and any unforeseen  events to the alarm center.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-3-6683fbce1620e.webp")
    },
    {
        id: 57,
        category: 8,
        subcategory: 1,

        title: "Pedestrian Detection Camera",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-7-6683fbd1695c5.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-2-6683fbcc8229e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-2-6683fbcc8229e.webp"),
        Descriptipon2: "AHD1080P//960P/720P BSD Camera",
        Descriptipon1: "Video recording 1CH/3CH AHD 720P/1080P",
        Descriptipon3: "DSM with AI algorithm ,detect and alert drowsiness, distraction, smoking, phone calling, and more.",
        modalDescriptipon1: "ADAS includes Forward Collision Warning (FCW), Lane Departure  Warning (LDW), Pedestrian Detection (PD), and Vehicle Proximity  Alert",
        modalDescriptipon2: "3 ways Trig Trigger priority:V2>V1>V3",
        modalDescriptipon3: "BSD function employs real-time intelligent detection of pedestrians and cyclists alongside the vehicle, providing timelywarnings to the driver.",
        modalDescriptiponp: "CMS It is a centralised monitoring and alarm system. Through  a 4G network, it real-time transmits detailed information exterior conditions, the driver's work status, and any unforeseen  events to the alarm center.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-7-6683fbd1695c5.webp")
    },
    {
        id: 58,
        category: 8,
        subcategory: 1,

        title: "Pedestrian Detection Camera-Display",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-4-6683fbce22cd5.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-9-6683fbd2d83b2.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-2-6683fbcc8229e.webp"),
        Descriptipon2: "AHD1080P//960P/720P BSD Camera",
        Descriptipon1: "Video recording 1CH/3CH AHD 720P/1080P",
        Descriptipon3: "DSM with AI algorithm ,detect and alert drowsiness, distraction, smoking, phone calling, and more.",
        modalDescriptipon1: "ADAS includes Forward Collision Warning (FCW), Lane Departure  Warning (LDW), Pedestrian Detection (PD), and Vehicle Proximity  Alert",
        modalDescriptipon2: "3 ways Trig Trigger priority:V2>V1>V3",
        modalDescriptipon3: "BSD function employs real-time intelligent detection of pedestrians and cyclists alongside the vehicle, providing timelywarnings to the driver.",
        modalDescriptiponp: "CMS It is a centralised monitoring and alarm system. Through  a 4G network, it real-time transmits detailed information exterior conditions, the driver's work status, and any unforeseen  events to the alarm center.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/vms-8-6683fbd1b1dc6.webp")
    },



    // 99999999999999999999999999999999999

    {
        id: 59,
        category: 9,
        subcategory: 1,



        title: "WATER MANAGEMENT",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/water-manager-tank-1-6683fbd305a18.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/water-manager-tank-1-6683fbd305a18.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/water-manager-tank-1-6683fbd305a18.webp"),
        Descriptipon1: "Easily Installable Wireless Device",
        Descriptipon3: "Powered by AA-Standard Battery durability of 2 Years",
        Descriptipon2: "Maintenance free, Potential free sensors",
        modalDescriptipon1: "Single controller to fill-up multiple tanks",
        modalDescriptipon2: "Dry run: Motor will automatically stop, when water flow is not detected",
        modalDescriptipon3: "Easy Water Level Management: Automatically fills and refills the tank to defined levels.",
        modalDescriptiponp: "High/Low Voltage protection: Motor will stop, when voltage (180v-260v) not in range.",
        modalDescriptiponp2: "Tank Manager System is designed for filling water from sump/bore well / open well to over head tank/storage tank",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/water-manager-tank-1-6683fbd305a18.webp")
    },




    // 100000000000000000000000000000000
    {
        id: 60,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-1-6683fb57f1955.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-1-6683fb57f1955.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-1-6683fb57f1955.webp"),
        Descriptipon1: "LCD display",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-1-6683fb57f1955.webp")
    },
    {
        id: 61,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-2-6683fb58881de.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-2-6683fb58881de.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-2-6683fb58881de.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-2-6683fb58881de.webp")
    },
    {
        id: 62,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-3-6683fb5906e98.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-3-6683fb5906e98.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-3-6683fb5906e98.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-3-6683fb5906e98.webp")
    },
    {
        id: 63,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-4-6683fb59b2519.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-4-6683fb59b2519.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-4-6683fb59b2519.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-4-6683fb59b2519.webp")
    },
    {
        id: 64,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-5-6683fb5a25198.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-5-6683fb5a25198.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-5-6683fb5a25198.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-5-6683fb5a25198.webp")
    },
    {
        id: 65,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-6-6683fb5b22eca.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-6-6683fb5b22eca.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-6-6683fb5b22eca.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-6-6683fb5b22eca.webp")
    },
    {
        id: 66,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-7-6683fb5b01f2e.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-7-6683fb5b01f2e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-7-6683fb5b01f2e.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-7-6683fb5b01f2e.webp")
    },
    {
        id: 67,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-8-6683fb5bcb82e.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-8-6683fb5bcb82e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-8-6683fb5bcb82e.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-8-6683fb5bcb82e.webp")
    },
    {
        id: 68,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-9-6683fb5c0655d.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-9-6683fb5c0655d.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-9-6683fb5c0655d.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-9-6683fb5c0655d.webp")
    },
    {
        id: 69,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-10-6683fb5c8a47a.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-10-6683fb5c8a47a.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-10-6683fb5c8a47a.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-10-6683fb5c8a47a.webp")
    },
    {
        id: 70,
        category: 10,
        subcategory: 1,

        title: "ELECTRIC FENCE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-11-6683fb5cb455f.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-11-6683fb5cb455f.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-11-6683fb5cb455f.webp"),
        Descriptipon1: "Anti-bypass",
        Descriptipon2: "Network alarm & Remote control",
        Descriptipon3: "Multiple communication interfaces",
        modalDescriptipon1: "Alarm linkage monitoring images, the scene situation can be grasped in time. It has two modes: pulse mode and smart mode (net + pulse)",
        modalDescriptipon2: " Built-in network module to achieve interconnection with the Internet Multi-level networked remote controller",
        modalDescriptipon3: "I2.8-inch TFT color LCD display Comes with a control keyboard that can realize flexible control of multiple functions",

        modalDescriptiponp: "Multiple defense zones can be deployed and disarmed independently, 4/6 wire system, high and low voltage switching",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/electric-fence-11-6683fb5cb455f.webp")
    },






    ///11111111111111111111111111111111111111111111///////////////////
    {


        id: 71,
        category: 11,
        subcategory: 1,

        title: "KITCHEN SAFETY",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/kitchen-6683fb6800dfa.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/kitchen-6683fb6800dfa.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/kitchen-6683fb6800dfa.webp"),
        Descriptipon1: "High torque motor",
        Descriptipon2: "Easy installation",
        Descriptipon3: "Intelligent linkage | Remote control",
        modalDescriptipon1: "Memory -Recalls the highest CO concentration detected",
        modalDescriptipon3: "SWITCH Test/Reset - Tests the unit's electronic circuitry operation and allows you to immediately silence the alarm.",
        modalDescriptipon2: "LPG, Natural Gas Alarm Detector with Solenoid Valve and Siren",
        modalDescriptiponp: "Working - When the combustible gas concentration reaches the alarm value, the red alarm indicator keeps flashing and the Alarm keeps 1 sound ﻿per second. When the concentration of carbon monoxide reaches the alarm value, the red alarm indicator is kept on light, the Alarm keeps 2 sounds per second.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/kitchen-6683fb6800dfa.webp")
    },
    {


        id: 72,
        category: 11,
        subcategory: 1,

        title: "ELECTRONIC VALVE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/e-valve-6683fb5786894.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/e-valve-6683fb5786894.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/e-valve-6683fb5786894.webp"),
        Descriptipon1: "High torque motor",
        Descriptipon2: "Easy installation",
        Descriptipon3: "Intelligent linkage | Remote control",
        modalDescriptipon1: "Memory -Recalls the highest CO concentration detected",
        modalDescriptipon3: "SWITCH Test/Reset - Tests the unit's electronic circuitry operation and allows you to immediately silence the alarm.",
        modalDescriptipon2: "LPG, Natural Gas Alarm Detector with Solenoid Valve and Siren",
        modalDescriptiponp: "Working - When the combustible gas concentration reaches the alarm value, the red alarm indicator keeps flashing and the Alarm keeps 1 sound ﻿per second. When the concentration of carbon monoxide reaches the alarm value, the red alarm indicator is kept on light, the Alarm keeps 2 sounds per second.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/e-valve-6683fb5786894.webp")
    },
    {


        id: 73,
        category: 11,
        subcategory: 1,

        title: "ELECTRONIC VALVE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/e-valve-1-6683fb565dac5.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/e-valve-1-6683fb565dac5.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/e-valve-1-6683fb565dac5.webp"),
        Descriptipon1: "High torque motor",
        Descriptipon2: "Easy installation",
        Descriptipon3: "Intelligent linkage | Remote control",
        modalDescriptipon1: "Memory -Recalls the highest CO concentration detected",
        modalDescriptipon3: "SWITCH Test/Reset - Tests the unit's electronic circuitry operation and allows you to immediately silence the alarm.",
        modalDescriptipon2: "LPG, Natural Gas Alarm Detector with Solenoid Valve and Siren",
        modalDescriptiponp: "Working - When the combustible gas concentration reaches the alarm value, the red alarm indicator keeps flashing and the Alarm keeps 1 sound ﻿per second. When the concentration of carbon monoxide reaches the alarm value, the red alarm indicator is kept on light, the Alarm keeps 2 sounds per second.",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/e-valve-1-6683fb565dac5.webp")
    },




    // /122222222222222222212222222222222222/////////////////
    {
        id: 74,
        category: 12,
        subcategory: 1,

        title: "SOLAR FLASHING SIREN",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-1-6683fbc28b097.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-1-6683fbc28b097.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-1-6683fbc28b097.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-1-6683fbc28b097.webp")

    },
    {
        id: 75,
        category: 12,
        subcategory: 1,


        title: " FLASHING SIREN",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-4-6683fbc3d794a.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-4-6683fbc3d794a.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-4-6683fbc3d794a.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-4-6683fbc3d794a.webp")

    },
    {
        id: 76,
        category: 12,
        subcategory: 1,

        title: " FLASHING SIREN",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-2-6683fbc2d4cd5.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-2-6683fbc2d4cd5.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-2-6683fbc2d4cd5.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-2-6683fbc2d4cd5.webp")

    },
    {
        id: 77,
        category: 12,
        subcategory: 1,

        title: " FLASHING SIREN",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-3-6683fbc34ef8e.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-3-6683fbc34ef8e.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-3-6683fbc34ef8e.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/siren-3-6683fbc34ef8e.webp")

    },
    {
        id: 78,
        category: 12,
        subcategory: 1,

        title: "WIRELESS REPEATER",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/repeator-6683fbbfa4abd.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/repeator-6683fbbfa4abd.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/repeator-6683fbbfa4abd.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/repeator-6683fbbfa4abd.webp")

    },
    {
        id: 79,
        category: 12,
        subcategory: 1,

        title: "EMERGENCY BUTTON",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/emergency-6683fb5d817eb.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/emergency-6683fb5d817eb.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/emergency-6683fb5d817eb.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/emergency-6683fb5d817eb.webp")

    },
    {
        id: 80,
        category: 12,
        subcategory: 1,

        title: "EMERGENCY BUTTON",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/emergency-button-6683fb5d4f493.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/emergency-button-6683fb5d4f493.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/emergency-button-6683fb5d4f493.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/emergency-button-6683fb5d4f493.webp")

    },

    {
        id: 81,
        category: 12,
        subcategory: 1,

        title: "WIRELESS KEYPAD",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/keypad-6683fb6791319.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/keypad-6683fb6791319.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/keypad-6683fb6791319.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/keypad-6683fb6791319.webp")

    },
    {
        id: 82,
        category: 12,
        subcategory: 1,

        title: "WIRELESS KEYPAD",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/keypad-1-6683fb666d9dc.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/keypad-1-6683fb666d9dc.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/keypad-1-6683fb666d9dc.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/keypad-1-6683fb666d9dc.webp")

    },
    {
        id: 83,
        category: 12,

        subcategory: 1,
        title: "RF ROMOTE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp")

    },
    {
        id: 84,
        category: 12,
        subcategory: 1,

        title: "RF ROMOTE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-1-6683fbbfac42d.webp")

    },
    {
        id: 85,
        category: 12,
        subcategory: 1,

        title: "RF ROMOTE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-3-6683fbc08d934.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-3-6683fbc08d934.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-3-6683fbc08d934.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-3-6683fbc08d934.webp")

    },

    // id133333333333333333333333
    {
        id: 86,
        category: 12,
        subcategory: 1,

        title: "RF ROMOTE",
        modalimage1: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-3-6683fbc08d934.webp"),
        modalimage2: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-3-6683fbc08d934.webp"),
        modalimage3: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-3-6683fbc08d934.webp"),
        Descriptipon1: "AI Function",
        Descriptipon2: "AI Function",
        Descriptipon3: "Personalization",
        modalDescriptipon1: "Personalization",
        modalDescriptipon2: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptipon3: "16 Ch IP Ca-meras & 64 Wireless Sensors",
        modalDescriptiponp: "NYSTAIoT  NVR+Alarm Combo has become a cost-effective security system that integrates video surveillance, AI video analysis, video intercom, one-click alarm, access control and attendance, security alarm, fire alarm, music broadcast, and smart appliances, which control 80% of functions in the fields of security, fire protection, and DIGITAL CLASSROOM",
        image: require("../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/rf-3-6683fbc08d934.webp")

    },

]
