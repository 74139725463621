import React from "react";
import './footer.css'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.css'
import appstore1 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/appstore-1 (2).svg'
import appstore2 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/appstore-1 (1).svg'


export default function Footerproduct() {
  const currentYear = new Date().getFullYear()

  return (
    <>


      {/* footer desktop start */}
      <footer class="site-footer ">
        <div class=" bgimginfooter">
          <div class="container-fluid pt-4">
            <div class="row d-flex align-items-center justify-content-center" >
              <div class="col-xs-6 col-md-2">
                <h6 className="footertopic mb-3"> Company</h6>
                <ul class="footer-links">
                  <li><p href="http://localhost:3001/">About Us</p></li>
                  <li><p >Career</p></li>
                  <li><p >Compliance</p></li>
                  <li><p >Quality Policy</p></li>
                  <li><p >Case Studies</p></li>
                  <li><p >Nystai eLearning</p></li>
                </ul>
              </div>

              <div class="col-xs-6 col-md-2">
                <h6 className="footertopic mb-3">Support</h6>
                <ul class="footer-links">
                  <li><p ><Link to="/nystai-support" style={{ color: "#ADADAD" }}>Contact Us</Link></p></li>
                  <li><p >Submit a Ticket </p></li>
                  <li><p >Consultations</p></li>
                  <li><p >Where to Buy</p></li>

                  <li><p >FAQ & Help</p></li>
                  <li><p >BLOG</p></li>

                </ul>
              </div>
              <div class="col-xs-6 col-md-2">
                <h6 className="footertopic mb-3">Protect Plan </h6>
                <ul class="footer-links">
                  <li><p >Virtual Guard</p></li>
                  <li><p >Alarm</p></li>
                  <li><p >Camera & Alarm </p></li>
                  <li><p >Access Control</p></li>
                  <li><p >Ai-VMS </p></li>
                  <li><p >Electric Fence </p></li>
                </ul>
              </div>

              <div class="col-xs-6 col-md-2">
                <h6 className="footertopic mb-3">Solutions</h6>
                <ul class="footer-links">
                  <li><p >Residential</p></li>
                  <li><p >Industrial & Warehouse</p></li>
                  <li><p >Banking </p></li>
                  <li><p >Retail </p></li>
                  <li><p >Education</p></li>
                  <li><p >Health Care</p></li>
                </ul>
              </div>
              <div class="col-xs-6 col-md-2 ">

                <div className="d-flex align-items-center justify-content-center" style={{ flexDirection: "column" }}>
                  <p class=" mb-2" target="_blank">
                    <button type="button" class="btn nys-footer-btn  mb-1" > <FontAwesomeIcon icon={faYoutube} className="  me-2 " />Subscribe Newsletter</button>
                  </p>


                  <a class=" mb-2" href="https://play.google.com/store/apps/details?id=com.sdfornystalnew&pcampaignid=web_share" target="_blank" rel="noreferrer">
                    <img class="news-app-promo__play-store" src={appstore1} width="161" height="auto" border="0" alt="nys-img" />
                  </a>
                  <a class=" mb-2" href="https://apps.apple.com/in/app/nystai/id6503040541" target="_blank" rel="noreferrer">
                    <img class="news-app-promo__app-store" src={appstore2} width="161" height="auto" border="0" alt="nys-img" />
                  </a>

                </div>



              </div>




              <div class="col-xs-6 col-md-1 ">
                <div class="row ">


                  <div className="col-6 mt-4">
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <p target="_blank" rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faYoutube}
                            className="me-3 mb-3"
                            style={{ fontSize: "25px", color: "#FF0000" }}
                          />
                        </p>
                      </li>
                      <li>
                        <p target="_blank" rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faFacebook}
                            className="me-3 mb-3"
                            style={{ fontSize: "25px", color: "#1877F2" }}
                          />
                        </p>
                      </li>
                      <li>
                        <p target="_blank" rel=" noreferrer">
                          <FontAwesomeIcon
                            icon={faInstagram}
                            className="me-3 mb-3"
                            style={{ fontSize: "25px", color: "#E4405F" }}
                          />
                        </p>
                      </li>

                    </ul>
                  </div>
                  <div className="col-6 mt-4">
                    <ul style={{ listStyle: "none" }}>

                      <li>
                        <p target="_blank" rel=" noreferrer">
                          <FontAwesomeIcon
                            icon={faXTwitter}
                            className="me-3 mb-3"
                            style={{ fontSize: "25px", color: "#fff" }}
                          />
                        </p>
                      </li>
                      <li>
                        <p target="_blank" rel=" noreferrer">
                          <FontAwesomeIcon
                            icon={faTwitter}
                            className="me-3 mb-3"
                            style={{ fontSize: "25px", color: "#1DA1F2" }}
                          />
                        </p>
                      </li>
                      <li>
                        <p target="_blank" rel=" noreferrer">
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            className="me-3 mb-3"
                            style={{ fontSize: "25px", color: "#0077B5" }}
                          />
                        </p>
                      </li>
                    </ul>
                  </div>


                </div>
              </div>

            </div>



            <hr className="container" />
          </div>


          <div class="container">
            <div class=" copy-right-nys-footer">
              <div >
                <p class="copyright-text">Copyright &copy; {currentYear} All Rights Reserved by ADHIBA TECHNOLOGIES PRIVATE LIMITED</p>
              </div>

              <div class="d-flex">
                <p className="ptw-text-footer-nystai"><Link to="/nystai-privacy-policy" style={{ color: "#adadad" }}>Privacy  Policy</Link> |</p>
                <p className="ptw-text-footer-nystai"> <Link to="/nystai-warrenty" style={{ color: "#adadad" }}>Warrenty |</Link> </p>
                <p className="ptw-text-footer-nystai"> <Link to="/nystai-terms-condition" style={{ color: "#adadad" }} >TERMS & SERVICE</Link> </p>
              </div>
            </div>
          </div>


        </div>
      </footer>
      {/* footer desktop es */}

      {/* footer mobile start */}
      <section className=" mobile-footer-sec">
        <div>

          <div class="container accordion accordion-flush  accordion-nys-footer" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <p class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  Company
                </p>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <ul class="footer-links">
                    <li><p >About Us</p></li>
                    <li><p >Career</p></li>
                    <li><p >Compliance</p></li>
                    <li><p>Quality Policy</p></li>
                    <li><p >Case Studies</p></li>
                    <li><p >Nystai eLearning</p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <p class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  Support
                </p>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <ul class="footer-links">
                    <li><p ><Link to="/nystai-support" style={{ color: "#ADADAD" }}>Contact Us</Link></p></li>
                    <li><p >Submit a Ticket </p></li>
                    <li><p >Consultations</p></li>
                    <li><p >Where to Buy</p></li>

                    <li><p >FAQ & Help</p></li>
                    <li><p >BLOG</p></li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <p class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  Protect Plan

                </p>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <ul class="footer-links">
                    <li><p>Virtual Guard</p></li>
                    <li><p>Alarm</p></li>
                    <li><p>Camera & Alarm</p></li>
                    <li><p>Access Control</p></li>
                    <li><p>Ai-VMS</p></li>
                    <li><p>Electric Fence</p></li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-heading4">
                <p class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                  Solutions
                </p>
              </h2>
              <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <ul class="footer-links">
                    <li><p >Residential</p></li>
                    <li><p >Industrial & Warehouse</p></li>
                    <li><p >Banking </p></li>
                    <li><p >Retail </p></li>
                    <li><p >Education</p></li>
                    <li><p >Health Care</p></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <div className="text-center mb-3">
            <p class=" mb-2" target="_blank">
              <button type="button" class="btn nys-footer-btn  mb-1" >  <p ><FontAwesomeIcon icon={faYoutube} className="  me-2 " />Subscribe Newsletter</p></button>
            </p>

          </div>
          <div className="text-center mb-3">
            <a class=" mb-2" href="https://play.google.com/store/apps/details?id=com.sdfornystalnew&pcampaignid=web_share" target="_blank" rel="noreferrer">
              <img class="news-app-promo__play-store" src={appstore1} width="161" height="auto" border="0" alt="nys-img" />
            </a>


          </div>
          <div className="text-center mb-3">
            <a class=" mb-2" href="https://apps.apple.com/in/app/nystai/id6503040541" target="_blank" rel="noreferrer">
              <img class="news-app-promo__app-store" src={appstore2} width="161" height="auto" border="0" alt="nys-img" />
            </a>


          </div>
          <div className="text-center ">

            <p >
              <p ><Link to="/nystai-privacy-policy" className="ptw-text-footer-nystai-mobile"
              >Privacy  Policy | </Link> </p>
              <p > <Link to="/nystai-warrenty" className="ptw-text-footer-nystai-mobile">Warrenty |</Link> </p>
              <p > <Link to="/nystai-terms-condition" className="ptw-text-footer-nystai-mobile" >TERMS & SERVICE</Link> </p>

            </p>

            <p className="ptw-text-footer-nystai-mobile">Copyright &copy; {currentYear} All Rights Reserved by ADHIBA TECHNOLOGIES PRIVATE LIMITED</p>
          </div>
        </div>
      </section>
      {/* footer mobile end */}


    </>
  )
}