import React from "react";
import './support.css'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile } from '@fortawesome/free-solid-svg-icons';
import salesicon from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/group-10-668405abae20c.webp'
import serviesicon from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/faq-668405a9687a6.webp'
import faqs from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/faq-668405a9687a6.webp'
import whatsapp from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/subtract-668405adc24fa.webp'
import CHATWITHUS from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/pngwingcom-668405ac87a41.webp'
import Footer_product from "../A-LAYOUT/footer";
// new form
import { FaUser, FaEnvelope, FaBuilding, FaCity, FaGlobe, FaAddressCard, FaCommentAlt, FaBox } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import emailjs from '@emailjs/browser';
import CountryList from 'react-select-country-list';
import Select from 'react-select';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import supportban from "../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/Nystai support (1).png"
import 'react-toastify/dist/ReactToastify.css';



export default function Support() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        city: '',
        country: '',
        address: '',
        message: '',
        product: '',
        privacy: false,
    });

    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [recaptchaError, setRecaptchaError] = useState('');
    const countryOptions = CountryList().getData();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneChange = (phone) => {
        setFormData({ ...formData, phone });
    };

    const handleCountryChange = (selectedOption) => {
        setFormData({ ...formData, country: selectedOption.label });
    };

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, privacy: e.target.checked });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!recaptchaVerified) {
            setRecaptchaError('Please verify you are not a robot.');
            return;
        }

        emailjs
            .sendForm('service_lyfqebm', 'template_izyyodh', e.target, '7LZ7Pyk8u84wlH4qs')
            .then(
                () => {
                    toast.success('Form submitted successfully!');
                    handleReset();
                },
                () => {
                    toast.error('Failed to submit the form. Please try again.');
                }
            );
    };

    const handleReset = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            company: '',
            city: '',
            country: '',
            address: '',
            message: '',
            product: '',
            privacy: false,
        });
        setRecaptchaVerified(false);
        setRecaptchaError('');
    };

    const handleRecaptcha = (token) => {
        setRecaptchaVerified(!!token);
        setRecaptchaError('');
    };
    const CustomPlaceholder = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaGlobe style={{ marginRight: 8 }} />
            <span>Country</span>
        </div>
    );

    const openChatbot = () => {
        if (window.$zoho && window.$zoho.salesiq) {
            window.$zoho.salesiq.floatwindow.visible("show");
        }
    };

    useEffect(() => {
        openChatbot();
    }, []);


    return (
        <>

            <div className='ban-img'>
                <h1>CONTACT US</h1>
            </div>

            <div className='d-flex img-form container-fluid ' style={{ padding: "0px 5% 0PX 5%" }}>

                <div className="col-lg-6 col-md-6 col-sm-12 contact-right-support-nystai" style={{ marginTop: "80px" }}>
                    <div style={{ marginBottom: '40px' }} className="pb-3">
                        <h1 className='' style={{ color: '#1b6763', fontSize: '3rem', textAlign: "center" }}>Get In Touch</h1>
                        <span className='' style={{ fontSize: '1rem' }}>
                            If you have any questions about our products or services, please fill out the below form
                        </span>
                    </div>

                    <div style={{ marginBottom: '40px' }}>
                        <h6 className="text-nav-topics-nystai pb-3" style={{ fontSize: "1.75rem" }}>FAQs</h6>
                        <a href="https://wa.me/+918189977700" target="_blank">
                            <img src={faqs} className="whats-icon-support-nystai" style={{ height: '10vh', width: 'fit-content' }} />
                        </a>

                    </div>


                    <div className="col-12 " style={{ marginBottom: '40px' }}>

                        <h6 className="text-nav-topics-nystai pb-3" style={{ fontSize: "1.75rem" }}>CHAT WITH  US</h6>
                        <div className=" row">
                            <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center " style={{ borderRight: "1px solid #1b6763" }}>
                                <a href="https://wa.me/+918189977700" target="_blank">
                                    <img src={whatsapp} className="whats-icon-support-nystai" style={{ height: '10vh', width: 'fit-content' }} />
                                </a>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center ">


                                <img src={CHATWITHUS} className="whats-icon-support-nystai" style={{ height: '10vh', width: 'fit-content', cursor: "pointer" }} onClick={openChatbot} />


                            </div>

                        </div>
                    </div>

                    <div style={{ marginBottom: '50px' }}>


                        <div >
                            <h6 className="text-nav-topics-nystai pb-3" style={{ fontSize: "1.9rem" }}>CONTACT US</h6>
                            <h1 style={{ fontSize: '4em' }}> <FontAwesomeIcon icon={faMobile} shake style={{ color: "#1d736f", }} className="me-1 " />+91 81899 77700</h1>
                        </div>
                    </div>

                </div>

                <form className="contact-form col-lg-6" onSubmit={handleSubmit}>
                    <div>
                        <h1 className='' style={{ color: '#1b6763', fontSize: '3rem', textAlign: "center" }}>Get In Touch</h1>
                        <span className='' style={{ fontSize: '1rem' }}>
                            If you have any questions about our products or services, please fill out the below form
                        </span>
                    </div>
                    <div className="form-row row mt-3">
                        <div className="form-group col-lg-6">
                            <FaUser className="form-icon" />
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group col-lg-6">
                            <FaEnvelope className="form-icon" />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="form-group col-lg-6 phone-input-group">
                            <PhoneInput
                                country={'cn'}
                                value={formData.phone}
                                onChange={handlePhoneChange}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    placeholder: 'Phone Number',
                                }}
                            />
                        </div>
                        <div className="form-group col-lg-6">
                            <FaBuilding className="form-icon" />
                            <input
                                type="text"
                                name="company"
                                placeholder="Company"
                                value={formData.company}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="form-group col-lg-6">
                            <FaCity className="form-icon" />
                            <input
                                type="text"
                                name="city"
                                placeholder="City"
                                value={formData.city}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group col-lg-6">
                            <Select
                                options={countryOptions}
                                onChange={handleCountryChange}
                                placeholder={<CustomPlaceholder />}
                                value={formData.country}
                                className="react-select-container"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="form-group col-lg-6">
                            <FaAddressCard className="form-icon1" />
                            <textarea
                                name="address"
                                placeholder="Address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                                style={{ resize: "none" }}
                            ></textarea>
                        </div>
                        <div className="form-group col-lg-6">
                            <FaCommentAlt className="form-icon1" />
                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                style={{ resize: "none" }}
                            ></textarea>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="form-group ">
                            <FaBox className="form-icon" />
                            <select
                                name="product"
                                value={formData.product}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>
                                    Select Product
                                </option>
                                <option value="Product 1">Product 1</option>
                                <option value="Product 2">Product 2</option>
                                <option value="Product 3">Product 3</option>
                                <option value="Product 4">Product 4</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row row full-width">
                        <label className="privacy-policy">
                            <input
                                type="checkbox"
                                name="privacy"
                                checked={formData.privacy}
                                onChange={handleCheckboxChange}
                                required
                            />
                            <p>
                                I consent to receive emails about news, marketing & product
                                updates from Delta in accordance with the<Link to="/nystai-privacy-policy"> Privacy policy</Link>
                            </p>

                        </label>
                    </div>
                    <div className=" captcha-row">
                        <ReCAPTCHA
                            sitekey="6Lc7niYqAAAAAPxGa2UC1tP2dUv4I9cdMXLgMgMd"
                            onChange={handleRecaptcha}
                        />
                        {recaptchaError && <div className="recaptcha-error">{recaptchaError}</div>}
                        <div className="d-flex pt-4">
                            <button type="button" className="button-reset" onClick={handleReset}>
                                Reset
                            </button>
                            <button type="submit" className="button-submit ms-3">Submit</button>

                        </div>
                    </div>



                    <ToastContainer />
                </form>
            </div>

            {/* <div className="col-12">
                <img src={supportban} className="img-fluid" />
            </div> */}

            <section className="container-fluid">
                <div className="row">
                
                </div>
            </section>

            <div class="container ">

                <div class=" common-top-div">
                    <h6 class="head-h-divtopic">
                        <span style={{ color: "#1b6763" }}>NYST</span>AIoT
                    </h6>
                    <h3 class="head-h-divtopic"></h3>
                    <h6 class="sechead-h-divtop">
                        The Maiden Unified platform for Video Alarm and Access control Solutions
                    </h6>
                </div>

                <div class="card mb-3" style={{ backgroundColor: "#1b6763", borderRadius: "30px" }}>
                    <div class="row g-0">
                        <div class="col-md-12 col-lg-4 svg-arrow-nystai" >
                            <svg class="block-online-demo__arrows" width="250" height="350" viewBox="0 0 285 434" fill="none">

                                <path
                                    d="M270.8 362.7C221 332 183.4 280.5 159.1 209.6 138.8 150.5 131.9 89.7 129.7 49L157 47.6C159.1 86.5 165.6 144.7 184.9 200.8 207 265.5 240.8 312.1 285 339.4L270.8 362.7Z"
                                    fill="#309C2F" />
                                <path
                                    d="M129.9 51L100.7 81.2C98.7 83.3 96.3 83.3 94.2 81.3L81.1 68.6C79 66.6 79 64.2 81 62.1L139.5 1.6C141.5-0.5 143.9-0.5 146 1.5L206.4 60C208.5 62.1 208.5 64.4 206.5 66.6L193.7 79.7C191.7 81.9 189.4 81.9 187.2 79.8L157.3 50.8 129.9 51Z"
                                    fill="#309C2F" />
                                <path
                                    d="M14.3 362.7L0 339.4C31.8 319.8 58.3 291 78.9 253.5 95.6 223.1 108.5 187 117.1 146.2 131.9 76 128.9 15.9 128.8 15.3L156 13.8C156.1 16.3 159.4 77.7 143.9 151.2 134.7 194.7 121 233.4 103 266.4 80.1 308.1 50.3 340.5 14.3 362.7Z"
                                    fill="#0F306E" />
                                <path
                                    d="M81.4 365.4L60 348.5C139.6 247.1 128.6 17.6 128.4 15.3L155.6 13.8C155.7 16.2 158.8 74.6 151.4 147 141.3 245.6 117.8 319.1 81.4 365.4Z"
                                    fill="#0040DB" />
                                <path d="M156.3 26.9L129.1 26.9 129.1 351.1 156.3 351.1 156.3 26.9Z"
                                    fill="#00E6A7" />
                                <path
                                    d="M203 365.4C166.7 319.1 143.1 245.5 133.1 147 125.6 74.7 128.6 16.2 128.8 13.8L156 15.3 142.4 14.6 156 15.3C155.9 17.6 144.8 247 224.4 348.5L203 365.4Z"
                                    fill="#085440" />
                                <path
                                    d="M270.8 362.7C221 332 183.4 280.5 159.1 209.6 138.8 150.5 131.9 89.7 129.7 49L157 47.6C159.1 86.5 165.6 144.7 184.9 200.8 207 265.5 240.8 312.1 285 339.4L270.8 362.7Z"
                                    fill="#F6F4EF" />
                                <path
                                    d="M129.9 51L100.7 81.2C98.7 83.3 96.3 83.3 94.2 81.3L81.1 68.6C79 66.6 79 64.2 81 62.1L139.5 1.6C141.5-0.5 143.9-0.5 146 1.5L206.4 60C208.5 62.1 208.5 64.4 206.5 66.6L193.7 79.7C191.7 81.9 189.4 81.9 187.2 79.8L157.3 50.8 129.9 51Z"
                                    fill="#F6F4EF" />
                            </svg>
                        </div>
                        <div class="col-md-12 col-lg-8 d-flex align-items-center justify-content-center" >
                            <div class="card-body text-center">
                                <h1 class="standalone text-white">Ready to learn more?</h1>

                                <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                                    <button type="button" class="btn btn-outline-light me-3 bg-white text-dark"><Link to="/nystai-services">See Demo</Link></button>
                                    <button type="button" class="btn btn-outline-light"><Link to="/nystai-protplan">See Pricing</Link></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer_product />

        </>
    )
}