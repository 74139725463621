import React, { useRef, useState } from "react";
import './Homeslider.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cardslider1 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Video & alarm.png';
import cardslider2 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Smart-Home.png';
import cardslider3 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Fire alarm.png';
import cardslider4 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/kitchen safety.png';
import cardslider5 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Lighting.png';
import cardslider6 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/intruder alarm.png';
import cardslider7 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/cardbghome-2-6683ef3b3698d.webp';
import cardslider8 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/bugler alarm.webp';
import cardslider9 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/sound and light alarm.png';
import cardslider10 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/industrial.webp';
import cardslider11 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Water-leakage.png';
import cardslider12 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Tank-Manager.png';
import cardslider13 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/locker-safety.png';
import cardslider14 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Video Doorbell.png';
import cardslider15 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/car-camera.png';
import cardslider16 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Invisible-barrier.png';
import cardslider17 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Electric-Fence.png';
import cardslider18 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/access.webp';
import cardslider19 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/ofice-automation.png';
import cardslider20 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/Interactive Learning.png';
import cardslider21 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/smoke.webp';

export default function Slickcard() {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = 21;

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        autoplay: true,
        loop: false,
        autoplaySpeed: 4000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentSlide(next),
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>



            <div className="common-top-div">
                <h6 className="head-h-divtopic ">
                    <span style={{ color: "#1b6763" }}>NYST</span>AI-IoT
                </h6>

                <h6 className="sechead-h-divtop ">
                    The Maiden Unified platform for Video + Alarm and Access control Solutions
                </h6>
            </div>

            <section className="section-hover-card-nystai-home" style={{ overflow: "hidden" }}>
                <div className="slider">
                    <Slider
                        ref={sliderRef}
                        {...settings}
                    >
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider1}
                                        className="full-width img-fluid" alt="Cardhover1" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">Video and Alarm Linkage </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider2}
                                        className="full-width img-fluid" alt="Cardhover2" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 "> Smart Home</div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider3}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">  Fire Alarm </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider4}
                                        className="full-width img-fluid" alt="Cardhover4" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 "> Kitchen Safety </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider5}
                                        className="full-width img-fluid" alt="Cardhover5" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 "> Lighting
                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider6}
                                        className="full-width img-fluid" alt="Cardhover6" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 "> Intruder Detection
                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider7}
                                        className="full-width img-fluid" alt="Cardhover7" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 "> Perimeter Alarm
                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider8}
                                        className="full-width img-fluid" alt="Cardhover8" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 "> Bugler Alarm
                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider9}
                                        className="full-width img-fluid" alt="Cardhover2" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 "> Sound & Light Alarm
                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider10}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Industrial
                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider11}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Water Leakage

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider12}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Tank Manager

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider13}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Locker Safety

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider14}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Video Doorbell

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider15}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Car Camera

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider16}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Invisible Barriers

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider17}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Electric Fence

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider18}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Access Control

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider19}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Office Automation

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider20}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Interactive Learning

                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="rt-profile-card">
                                    <img
                                        src={cardslider21}
                                        className="full-width img-fluid" alt="Cardhover3" />
                                    <div className="rt-profile-body">
                                        <div className="rt-profile-name mb-3 ">
                                            Smoke Sensor
                                        </div>
                                        <div className="rt-profile-text">
                                            Seamlessly integrates with all third-party devices & networks from all brands at no extra cost.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>

                    <div className="arrow d-flex align-items-center justify-content-center mb-5">
                        <button
                            onClick={previous}
                            style={{
                                color: currentSlide === 0 ? 'black' : 'grey',
                                backgroundColor: currentSlide === 0 ? 'grey' : 'black'
                            }}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <button
                            onClick={next}
                            style={{
                                color: currentSlide >= totalSlides - settings.slidesToShow ? 'black' : 'grey',
                                backgroundColor: currentSlide >= totalSlides - settings.slidesToShow ? 'grey' : 'black'
                            }}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>
            </section>

            
        </>
    );
}
