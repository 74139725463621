// Import images
import img1 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/textcard1-1-6683ef8e4e735.webp';
import img2 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/slider-img-1-2-6683ef7858cd5.webp';
import img3 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/slider-img-1-6683ef84566d1.webp';
import img4 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/textcard1-1-6683ef8e4e735.webp';

// home-card-1-slider 
import image5 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/cardbghome-1-6683ef3a7799f.webp';
import image6 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/cardbghome-2-6683ef3b3698d.webp';
import image7 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/images for .com/intruder alarm.png';
import image8 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/cardbghome-4-6683ef3ccda19.webp';
import image9 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/cardbghome-5-6683ef3e5cc5c.webp';
import image10 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/cardbghome-6-6683ef4363d23.webp';
import image11 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/cardbghome-7-6683ef442b340.webp';
import image12 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/cardbghome-8-6683ef4512c16.webp';

// home-card-3-slider 
import image13 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/productcard-1-6683ef6b70019.webp'
import image14 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/productcard-2-6683ef6c13e6a.webp'
import image15 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/productcard-3-6683ef6c9a05a.webp'
import image16 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/productcard-4-6683ef6d4a64b.webp'
import image17 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/productcard-5-6683ef6dd074c.webp'
import image18 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/productcard-6-6683ef6e7b545.webp'
import image19 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/productcard-7-6683ef6f2c1e0.webp'
import image20 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/productcard-8-6683ef6fd7678.webp'

// home-logo-partner 
// Importing images 21 to 50
import image21 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/tamil-nadu-agricultural-university-logo-6683ef8cc5cff.webp';
import image22 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/tamil-nadu-veterinary-and-animal-science-university-logo-6683ef8d617c4.webp';
import image23 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/gilbergo-6683ef4ebd9da.webp';
import image24 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/annax-big-6683ef3745ccd.webp';
import image25 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/ccp-1-6683ef45b2a46.webp';
import image26 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/kerala-police-logo-8935219ef1-seeklogocom-6683ef66152ca.webp';
import image27 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/integra-6683ef63ef7f0.webp';
import image28 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/gpca-png-6683ef5002ed4.webp';
import image29 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/small-psg-college-of-technology-coimbatore-psg-tech-coimbatore-6c4611616d-3738143a27-086ff859c2-6683ef88e4029.webp';
import image30 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/eurofurn-logo-01-6683ef4e29591.webp';
import image31 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/2560px-reliance-digitalsvg-6683ef3019ede.webp';
import image32 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/hillrock-logo-with-estate-distillery-6683ef51b01c9.webp';
import image33 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/footer-logo-org-6683ef4e3a26e.webp';
import image34 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/pugo-ltd-6683ef724709b.webp';
import image35 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/advance-office-technologies-logo-120x120-6683ef36999a4.webp';
import image36 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/versa-6683ef9301775.webp';
import image37 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/pricol-logo-6683ef6aa0b53.webp';
import image38 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/infovenz-final-website-6683ef628441e.webp';
import image39 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/sri-venkateswara-carbonic-gases-p-limited-logo-120x120-6683ef8c3f953.webp';
import image40 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/elgi-6683ef4838637.webp';
import image41 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/e98141c4_ab66_4e5e_ab95_c278cdb26809.avif';
import image42 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/dayaseva-opc-pvt-ltd-logo-c9f4b4dc-6683ef470633a.jpg';
import image43 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/ikonic-logo-i-red-100x100-6683ef6068313.webp';
import image44 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/png-transparent-alstom-transport-logo-business-general-electric-business-blue-text-trademark-6683ef695813b.webp';
import image45 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/mjunction-logo-6683ef66ad032.webp';
import image46 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/1567245515234-6683ef3547fbd.webp';
import image47 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/idtxr4iqxk-6683ef5f8abba.webp';
import image48 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/glastronix-6683ef4eccfee.webp';
import image49 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/shriram-life-insurance-logo.svg';
import image50 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/bulk-myro-6683ef39be50a.webp';

// home-Rightleft
import image51 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/textcard1-1-6683ef8e4e735.webp';
import image52 from '../IMAGES-VIDEOS/A-IMG-HOME-LAYOUT/textcard1-2-6683ef8fd7ba9.webp';

// Get images function
import image53 from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/group-10-668405abae20c.webp'
import image54 from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/group-10-1-668405aa35009.webp'
import image55 from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/group-10-2-668405aae62fb.webp'
import image56 from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/subtract-668405adc24fa.webp'
import image57 from '../IMAGES-VIDEOS/F-IMG-SUPPORT-LAYOUT/pngwingcom-668405ac87a41.webp'

// Get images function
import image58 from '../IMAGES-VIDEOS/D-IMG-PLAN-LAYOUT/chart-668404e9b0d2f.webp'
import image59 from '../IMAGES-VIDEOS/D-IMG-PLAN-LAYOUT/crd-content-1-1-668404eba7849.webp'
import image60 from '../IMAGES-VIDEOS/D-IMG-PLAN-LAYOUT/crd-content-1-2-668404eb45a3a.webp'
import image61 from "../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/nvr-alarm-6683fb6ca7f08.webp"
import image62 from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/motion-sensor-1-6683fb6b56b6f.webp'
import image63 from "../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/alarm-pannel-6683fb502f121.webp"
import image64 from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/industrial-alarm-pannel-6683fb65404a8.webp'


// Get images function
import image65 from '../IMAGES-VIDEOS/C-IMG-SOLUTION-LAYOUT/sticky-1-668404568ba3b.webp'
import image66 from '../IMAGES-VIDEOS/C-IMG-SOLUTION-LAYOUT/sticky-2-66840456a370b.webp'
import image67 from '../IMAGES-VIDEOS/C-IMG-SOLUTION-LAYOUT/sticky-3-66840457676f3.webp'
import image68 from '../IMAGES-VIDEOS/C-IMG-SOLUTION-LAYOUT/icon-1-6684045359a6d.webp'

export function getImages() {
    return [
        img1,
        img2,
        img3,
        img4,

        // home-card-1-slider        
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,

        // home-card-2-slider 
        image13,
        image14,
        image15,
        image16,
        image17,
        image18,
        image19,
        image20,

        // home-logo-partner 
        image21, image22, image23, image24,
        image25, image26, image27, image28,
        image29, image30, image31, image32,
        image33, image34, image35, image36,
        image37, image38, image39, image40,
        image41, image42, image43, image44,
        image45, image46, image47, image48,
        image49, image50,

        // home-Rightleft
        image51,
        image52,

         // home-Rightleft
         image53,
         image54,
         image55,
         image56,
         image57,


         // home-Rightleft
         image58,
         image59,
         image60,
         image61,
         image62,
         image63,

         // home-Rightleft
        image64,
        image65,
        image66,
        image67,
        image68,

    ];
}
